import { Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import * as msgpack from '@msgpack/msgpack';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MsgPackStream, MsgPackStreamError } from 'msgpack-stream';
import { BluetoothWifiData } from '@app/types';
import { tap, map } from 'rxjs/operators';
import { RestEndpointsService } from '@app/api/rest-endpoints.service';
import { AuthQuery } from '@app/store/auth';
import { StellaKioskService } from '@app/shared/services/stella-kiosk.service';

export interface StellaNavigator extends Navigator {
  permissions: Permissions;
  bluetooth: any;
}

const STELLA_MSGPACK_SERVICE = '00000040-6567-7a6f-7465-63682e636f6d';
const STELLA_MSGPACK_CHARACTERISTIC = '00000041-6567-7a6f-7465-63682e636f6d';
const STELLA_BLE_CONFIGURATION = {
  filters: [
    // { services: [STELLA_MSGPACK_SERVICE]}
    { namePrefix: 'Stella'}
  ],
  optionalServices: [STELLA_MSGPACK_SERVICE],
  // acceptAllDevices: true
};

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {

  dataStream$ = new BehaviorSubject([]);
  connectionStream$ = new BehaviorSubject(false);

  device: any;
  characteristic: any;
  navigator: StellaNavigator;
  private msgpackStream = new MsgPackStream();
  bluetoothProvider: "browser" | "kiosk";

  constructor(
    private readonly rest: RestEndpointsService,
    private readonly authQuery: AuthQuery,
    public translate: TranslateService,
    private toastr: ToastrService,
    private stellaKiosk: StellaKioskService
  ) {
    this.navigator = navigator as StellaNavigator;
    this.bluetoothProvider = stellaKiosk.bluetooth ? "kiosk" : "browser";
  }
  handleNotifications(e: Event) {
    if ((e.target as any).value) {
      const view = (e.target as any).value as DataView;
      this.msgpackStream.push(new Uint8Array(view.buffer));
    }
  }

  scanNearbyStellas() {
    if (!this.stellaKiosk.bluetooth) {
      throw new Error("Cannot scan stellas using browser. This method is only available for non browser implementations.");
    }

    // return new Promise<{ id: string, name: string }[]>((resolve) => setTimeout(() => resolve([]), 10000));
    // return Promise.resolve([{ id: "abc", name: "lol" }])
    return this.stellaKiosk.bluetooth.getAvailableDevices();
  }

  private async waitForData() {
    for await (const obj of this.msgpackStream.start() as any) {
      if (obj instanceof MsgPackStreamError) {
        console.error('There was an error during a stream:' + obj.error);
        continue;
      }

      if (obj?.net?.available) {
        this.dataStream$.next(
          orderBy(
            obj.net.available.filter(n => n.ssid).filter(n => n.rssi > -90),
            ['rssi', 'ssid'],
            ['desc', 'desc']
          )
        );
      }
    }
  }

  private async prepareConnection(): Promise<{ device: any, characteristic: any, server: any }> {
    const device = await this.navigator.bluetooth.requestDevice(STELLA_BLE_CONFIGURATION);
    const server = await device.gatt.connect();
    const service = await server.getPrimaryService(STELLA_MSGPACK_SERVICE);
    const characteristic = await service.getCharacteristic(STELLA_MSGPACK_CHARACTERISTIC);
    return { device, characteristic, server };
  }

  async connect(stella?: { id: string, name: string }): Promise<void> {
    if (this.stellaKiosk.bluetooth) {
      if (!stella) {
        throw new Error("Stella device must be specified for non browser connection");
      }

      // this.dataStream$.next([{
      //   ssid: "test",
      //   rssi: -50,
      //   ch: 1,
      //   bssid: ""
      // }]);

      // return;

      this.stellaKiosk.bluetooth.getAvailableNetworks(stella.id).then(v => this.dataStream$.next(v.map(v => ({
        ssid: v,
        rssi: -50,
        ch: 1,
        bssid: ""
      }))));

      return;
    }

    try {
      const { device, characteristic, server } = await this.prepareConnection();

      device.addEventListener('gattserverdisconnected', this.onDisconnect.bind(this));
      characteristic.addEventListener('characteristicvaluechanged', this.handleNotifications.bind(this));
      this.waitForData();
      await characteristic.startNotifications();

      this.connectionStream$.next(true);
      this.showToast('btConfigure.bluetoothSuccess', 'success');

      this.device = device;
      this.characteristic = characteristic;
    } catch (err) {
      console.log(err);
      this.connectionStream$.next(false);
      this.showToast('btConfigure.bluetoothError', 'error');
    }
  }


  public syncWifis() {
    if (this.authQuery.isAuthorized()) {
    this.rest.fetchWifis()
      .pipe(
        map(wifis => wifis.map(wf => ({ ssid: wf.ssid, bssid: wf.bssid, pass: wf.password }))),
        tap(wifis => {
          if (wifis.length) {
            this.send({ net: { add: wifis } });
          }
        }),
      )
      .subscribe({
        next: () => this.showToast('btConfigure.wifiSyncSuccess', 'success'),
        error: () => this.showToast('btConfigure.wifiSyncError', 'error')
      });
    }
  }

  addWifi(data: BluetoothWifiData, stella?: { id: string, name: string } | null, clearOldNetworks?: boolean): Promise<any> {
    if (this.stellaKiosk.bluetooth) {
       if (!stella) {
        throw new Error("Stella device must be specified for non browser connection");
      }
      return this.stellaKiosk.bluetooth.setNetworkCredentials(stella.id, {
        ssid: data.ssid,
        password: data.pass,
        clearOldNetworks: clearOldNetworks ?? false
      });
    }

    if (!this.authQuery.isAuthorized()) {
      return this.send({ net: { add: [data] } });
    }
    return this.rest.addWifi({ ssid: data.ssid, bssid: data.bssid || '', password: data.pass })
      .pipe(
        tap(async _ => await this.send({ net: { add: [data] } }))
      )
      .toPromise();
  }

  private onDisconnect(event) {
    this.connectionStream$.next(false);
    this.msgpackStream.stop();
  }

  private showToast(msgSlug: string, method: 'error' | 'success') {
    this.translate.get(msgSlug)
      .pipe(
        tap(translation => this.toastr[method](translation, '')))
      .subscribe();
  }

  async closeConnection(): Promise<void> {
    this.connectionStream$.next(false);
    if (this.device) {
      await this.device.gatt.disconnect();
    }
    this.msgpackStream.stop();
  }

  send(data: BluetoothWifiData | any): Promise<void> {
    return this.characteristic.writeValue(msgpack.encode(data));
  }
}
