import { ChannelSignal, IChartController } from '@app/types';

const SIZE = 40;

export class BaseChartController implements IChartController {
  constructor(public chart) {
  }

  exerciseTime = 0;
  exerciseTimePerChannel: Record<string,number> = {};
  selectedChannel = 0;

  addData(signal: ChannelSignal): number {
    for(const chIdx in signal) {
      const size = (signal[chIdx] || []).length;
      this.exerciseTimePerChannel[chIdx] = (this.exerciseTimePerChannel[chIdx] || 0) + size;
    }
    
    this.exerciseTime = this.exerciseTimePerChannel[this.selectedChannel] || 0;
    return this.exerciseTime;
  }

  updateChannel(channel: number): void {
    this.selectedChannel = channel;
  }

  getExerciseTimePerChannel(channel: number) {
    return this.exerciseTimePerChannel[channel] ?? 0;
  }

  pushSingleSample(): void {
    this.exerciseTime += SIZE / 1000;
  }
}
