import { Injectable } from '@angular/core';

export interface EGZOTechHostApi {
  type?: "stella-app-kiosk" | "stella-app-carrier";
  getType?(): "stella-app-kiosk" | "stella-app-carrier";
  showRegisterDevicesPopup?(lang: string): void;
  getDevicesSerialNumbers?(): string[];
  getAvailableDevices?(callback: string): void;
  getAvailableNetworks?(deviceId: string, callback: string): void;
  setNetworkCredentials?(deviceId: string, networkInfo: { ssid: string, password: string, clearOldNetworks: boolean }, callback: string): void;
  overrides?: {
    directHubUrl?: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StellaKioskService {
  readonly bluetooth: {
    getAvailableDevices(): Promise<{ id: string, name: string }[]>;
    getAvailableNetworks(deviceId: string): Promise<string[]>;
    setNetworkCredentials(deviceId: string, networkInfo: { ssid: string, password: string, clearOldNetworks: boolean }): Promise<void>;
  } | null;

  get type() {
    const egzotechHostApi = this.getEGZOTechHostApi();

    return egzotechHostApi.type ?? egzotechHostApi.getType?.() ?? "browser";
  }

  constructor() {
    this.bluetooth = null;
    // this.bluetooth = {} as any;
    const egzotechHostApi = this.getEGZOTechHostApi();

    if (egzotechHostApi && egzotechHostApi.getAvailableDevices && egzotechHostApi.getAvailableNetworks && egzotechHostApi.setNetworkCredentials) {
      this.bluetooth = {
        getAvailableDevices() {
          let promiseResolve: (result: { id: string, name: string }[]) => void;
          let promiseReject: (reason: any) => void;

          const promise = new Promise<{ id: string, name: string }[]>((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
          });

          const callbackName = "_egzotechHostApi_$$_getAvailableDevicesCallback";
          (globalThis as any)[callbackName] = function(v: { id: string, name: string }[]) {
            promiseResolve(v);
          }

          egzotechHostApi.getAvailableDevices(callbackName);
          return promise;
        },
        getAvailableNetworks(deviceId: string) {
          let promiseResolve: (result: string[]) => void;
          let promiseReject: (reason: any) => void;

          const promise = new Promise<string[]>((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
          });

          const callbackName = "_egzotechHostApi_$$_getAvailableNetworksCallback";
          (globalThis as any)[callbackName] =  function(v: string[]) {
            promiseResolve(v);
          }

          egzotechHostApi.getAvailableNetworks(deviceId, callbackName);
          return promise;
        },
        setNetworkCredentials(deviceId: string, networkInfo: { ssid: string, password: string, clearOldNetworks: boolean }) {
          let promiseResolve: () => void;
          let promiseReject: (reason: any) => void;

          const promise = new Promise<void>((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
          });

          const callbackName = "_egzotechHostApi_$$_setNetworkCredentialsCallback";
          (globalThis as any)[callbackName] = function() {
            promiseResolve();
          }

          egzotechHostApi.setNetworkCredentials(deviceId, networkInfo, callbackName);
          return promise;
        },
      }
    }
  }

  getEGZOTechHostApi(): EGZOTechHostApi | null {
    return (window as any).egzotechHostApi as EGZOTechHostApi | null ?? null;
  }

  isKioskMode(): boolean {
    return this.type === 'stella-app-kiosk';
  }

  showRegisterDevicesPopup(currentLang: string) {
    const egzotechHostApi = this.getEGZOTechHostApi();

    egzotechHostApi.showRegisterDevicesPopup(currentLang);
  }

  getDirectHubOverride() {
    const egzotechHostApi = this.getEGZOTechHostApi();
    const params = new URLSearchParams(window.location.search);
    let paramsOverride = params.get("directHubUrlOverride") ?? localStorage.getItem("DIRECT_HUB_URL_OVERRIDE");

    if (paramsOverride) {
      window.localStorage.setItem("DIRECT_HUB_URL_OVERRIDE", paramsOverride);
    }

    if (params.get("directHubUrlOverride")) {
      location.href = location.origin;
    }

    return paramsOverride ?? egzotechHostApi?.overrides?.directHubUrl;
  }

}
