import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, Data } from '@angular/router';
import { BarChart } from '@app/charts/BarChart';
import { LineChart } from '@app/charts/LineChart';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardMode, STELLA_LAST_CHART_SCALE, STELLA_LAST_CHART_PCT_SCALE, STELLA_LAST_UNIT } from '@app/enums';
import { Exercise } from '@app/models/Exercise.model';
import { Pages } from '@app/pages';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { ExercisesQuery } from '@app/store/exercises/exercise.query';
import { validChannels } from '@app/training/ChannelResolver';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { ChannelSignal, ChartUnit, SelectedMuscle } from '@app/types';
import { COLOR_ARRAY } from '@app/utils/ColorDictionary';
import { EgzoRX } from '@app/utils/EgzoRX';
import { environment } from '@env/environment';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { delay, filter, flatMap, map, takeUntil, tap, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material';
import { MessageDialogComponent } from '@app/components/message-dialog/message-dialog.component';
import { BatteryService } from '@app/shared/services/low-battery.service';
import { BaseChartController } from '@app/training/services/chart-controllers/BaseChartController';
import { InitializeExerciseDialogComponent } from '../../../components/initializing-exercise-dialog/initialize-exercise-dialog.component'
import { BaseEmgComponent } from '../baseEmg.component';
import { BaseDiagnosticComponent } from '../baseDiagnostic.component';
import { EmgCalibrationComponent } from '@app/training/components/emg-calibration/emg-calibration.component';
import { TranslateService } from '@ngx-translate/core';
import { Point } from '@arction/lcjs';
import { getChartLabel, isChartUnit } from '@app/utils/utils';

const EMG_EXERCISE_MAP = {
  'view': 11,
  'relaxation_analysis': 50,
  'view_with_mvc': 51
}

enum ChartType {
  BAR = "bar",
  CHART = "chart",
}

@Component({
  selector: "sba-emg",
  templateUrl: "./emg.component.html",
  styleUrls: ["./emg.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmgComponent extends BaseDiagnosticComponent {
  private routeData: Data

  threshold = 0;
  selectedType: ChartType = ChartType.CHART;
  charts: {
    line: LineChart,
    bar: BarChart
  };
  max = +localStorage.getItem(STELLA_LAST_CHART_SCALE);
  unit: ChartUnit;

  hideNotImplementedFeature = environment.hideNotImplemented;
  unsubscribe$ = new Subject<void>();
  template: Exercise;
  showOnlyChannels: number[] = [6, 7];
  initialSetupDone = false;
  trainingReady = false;
  startTime: number = 0;
  timeBreaks = [];
  exerciseDuration: number = 0;

  constructor(
    readonly dialog: MatDialog,
    readonly stellaDirect: StellaDirectService,
    readonly sessionService: CurrentSessionService,
    readonly exerciseService: CurrentExerciseService,
    readonly router: Router,
    readonly dashboard: DashboardService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,

    location: Location,
    exercises: ExercisesQuery,
    batteryService: BatteryService,
    route: ActivatedRoute,
    exerciseQuery: ExercisesQuery,
  ) {
    super(stellaDirect, sessionService, exerciseService, exerciseQuery, dashboard, dialog, location, router, translateService, route, batteryService);

    this.activatedRoute.data.subscribe(data => { this.routeData = data })

    this.template = exercises.getEntity(EMG_EXERCISE_MAP[this.routeData.exerciseName] ?? 11);
    this.exerciseNameForInquiry = this.template.name;

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.state.current === 'INITIAL') {
        this.pause(false);
      }
    });

    const unit = localStorage.getItem(STELLA_LAST_UNIT);
    if( isChartUnit(unit ) && this.needsCalibration() ) {
      this.unit = unit;
    } else {
      this.unit = 'microvolts';
    }
  }

  protected handleData(signal: ChannelSignal): void {
    Object.entries<Float32Array>(signal).forEach(([key, vl]) => {
      const time = this.chartController.getExerciseTimePerChannel(Number(key)) / 1000;
      if (vl.length > 50) {
        for (let i = 0; i < vl.length; i += 50) {
          Object.values(this.charts).forEach(chart => {
            const point = {
              y: vl[i] * 1e6,
              x: time +  i / 1000,
            };
            chart.addValue(
              point,
              Number(key));
          });
        }
      } else {
        Object.values(this.charts).forEach(chart => {
          const point = {
            y: vl[0] * 1e6,
            x: time,
          };
          chart.addValue(
            point,
            Number(key)
          );
        });
      }
    });
    this.chartController.addData(signal) / 1000;
  }

  restart() {
    this.initialSetupDone = false;
  }

  async start() {
    await super.start();
    this.startTime = this.chartController.exerciseTime / 1000;
  }

  addTimestamp() {
    const timestamp = this.chartController ? this.chartController.exerciseTime : 0;
    const lastTimestamp = this.timeBreaks.length > 0 ? this.timeBreaks[this.timeBreaks.length - 1] : null;
    if( timestamp !== 0 && lastTimestamp !== timestamp) {
      this.timeBreaks.push(timestamp);
    }
  }

  updateExerciseDuration() {
    if (this.state.current === "RUNNING") {
      this.exerciseDuration +=
        this.chartController.exerciseTime / 1000 - this.startTime;
    }
    this.startTime = this.chartController.exerciseTime / 1000;
  }

  async onPause() {
    this.updateExerciseDuration();
    this.addTimestamp();
  }

  onStop(): void {
    this.updateExerciseDuration();
  }

  async ngOnDestroy() {
    await this.exerciseService.stop();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    super.ngOnDestroy();
  }

  async onStart(event) {
    await this.startTraining(event);
  }

  async startTraining(muscles: any[]) {
    this.initializeExerciseDialog = this.dialog.open( InitializeExerciseDialogComponent );
    this.initialSetupDone = true;
    this.muscles = muscles.map(m => ({ ...m })).sort((a, b) => a.channel - b.channel);
    this.setDisplayedChannels(this.muscles.map(m => m.channel));

    this.showOnlyChannels = this.showOnlyConnected(muscles);
    this.initializeEmgExercise(this.needsCalibration(), false)
      .pipe(
        tap(_ => this.initChart()),
        flatMap(() => this.performInstructionDialog()),
        flatMap(() => this.performAdditionalInstructionDialog()),
        tap(() => {
          this.chartController = new BaseChartController(
            this.charts.line
          );
          this.chartController.updateChannel(this.muscles[0].channel);
          if( this.chart instanceof LineChart) {
            this.chart.setCurrentChannel(this.muscles[0].channel);
          }
        }),
        tap(_ => {
          this.stellaDirect.cable$.pipe(
            filter(c => Boolean(c)),
            tap(cable => this.cable = cable),
            tap(_ => {
              Object.values(this.charts).forEach(chart => chart.setColorArray(COLOR_ARRAY.filter((_, i) => this.showOnlyChannels.includes(i))));
            })
          ).subscribe();
        }),
        flatMap(_ => this.initStella()),
        tap(async () => {
          await this.exerciseService.initializeExercise(this.template.name, { module: 'diag.emg', emgCalibration: this.maxes });
          await this.exerciseService.update({
            threshold: new Array(8).fill(this.threshold),
            muscles
          });
          this.trainingReady = true;
          this.initializeExerciseDialog.close();
          this.cdr.detectChanges();
        }),
      )
      .subscribe({
        next: _ => { },
        error: error => console.log(error)
      });
  }

  protected needsCalibration(): boolean {
    return this.exerciseNameForInquiry === 'view_with_mvc'
  }

  showOnlyConnected(muscles: SelectedMuscle[]): number[] {
    if (!muscles) {
      return [];
    }
    return validChannels(muscles).ids;
  }

  async initStella(): Promise<boolean> {
    if (super.initStella()) {
      try {
        this.exerciseService.source$
          .pipe(
            takeUntil(this.unsubscribe$),
            EgzoRX.signalAmplifierZip(1e6),
            tap((channels) => {
              for (const ch of channels) {
                this.values[ch.channel] = ch.average;
              }
            }),
            tap(() => this.values = this.values.map(v => v > 1e4 ? 0 : v)),
            map(_ => this.values
              .map(v => v || 0)
              .filter((val, index, arr) => {
                if (!this.showOnlyChannels || !this.showOnlyChannels.length) {
                  return true;
                }
                return this.showOnlyChannels.includes(index);
              })
            ),
            tap(_ => this.cdr.detectChanges()),
            catchError(err => {
              console.log(err);
              return of(err);
            })
          )
          .subscribe();
          return true;
      }
      catch (err) {
        console.warn(err);
        return false;
      }

    }
  }

  changeChannel(value: number[]) {
    super.changeChannel(value);
    this.setUnit(this.unit);
  }

  changeScale(event) {
    localStorage.setItem(this.unit === 'microvolts' ? STELLA_LAST_CHART_SCALE : STELLA_LAST_CHART_PCT_SCALE, event);
    this.max = event;
    this.updateChartsBasedOnScale();
  }

  readScale() {
    this.max = this.unit === 'microvolts' ? +localStorage.getItem(STELLA_LAST_CHART_SCALE) : +localStorage.getItem(STELLA_LAST_CHART_PCT_SCALE);
  }

  updateChartsBasedOnScale() {
    Object.values(this.charts).forEach(chart => {
      chart.setScale({
        min: 0,
        max: this.max
      });
    });
  }

  changeUnit(event: string) {
    if(this.needsCalibration()){
      localStorage.setItem(STELLA_LAST_UNIT, event);
    }
    if( !isChartUnit(event)) {
      event = 'microvolts' as ChartUnit;
    }

    this.unit = event as ChartUnit;


    if( !this.needsCalibration() ) {
      event = 'microvolts';
    }
    this.setUnit(event as ChartUnit);
    this.readScale();
    this.updateChartsBasedOnScale();

    Object.values(this.charts).forEach((chart) => {
      if( event === 'pctmvc') {
        chart.setFactors(this.getMaxesPerChannels());
      } else {
        chart.resetFactors();
      }
    })
  }

  getMaxesPerChannels() {
    const maxes = Array(8).fill(0);
    for( const chIdx in this.displayedChannels) {
      if(this.displayedChannels[chIdx]!==undefined) {
        maxes[this.displayedChannels[chIdx]] = this.maxes[chIdx];
      }
    }
    return maxes;
  }

  private setUnit(unit: ChartUnit) {
    Object.values(this.charts).forEach((chart) => {
      chart.setYAxisStrategy(unit);
      chart.setYAxisTitle(this.translateService.instant(unit === 'microvolts' ? 'common.units.yAxisMicrovolts' : 'common.units.pctmvc'));
    });
  }

  async endTraining() {
    this.addTimestamp();
    this.updateExerciseDuration();

    await this.exerciseService.update({
      timeBreaks: this.timeBreaks,
      duration: this.exerciseDuration * 1e6
    });

    if (this.dashboard.started) {
      this.dashboard.nextExercise();
    } else {
      if (this.dashboard.mode === DashboardMode.PATIENT) {
        await this.router.navigate([Pages.PATIENT_CALENDAR], { state: { force: false } });
      } else {
        await this.router.navigate([Pages.PATIENT_MEDICAL_CARD], { state: { force: false } });
      }
    }
  }

  goBack() {
    this.pause();
    super.goBack();
  }

  async handleThresholdChange(event) {
    this.threshold = event.value;
    await this.exerciseService.update({
      threshold: new Array(8).fill(event.value),
    });

    Object.values(this.charts).forEach(chart => {
      chart.thresholdLine.setValue(event.value);
    });
  }

  initChart() {
    this.charts = {
      bar: new BarChart({
        container: "chart-bar",
        threshold: this.threshold
      }, this.translateService.instant('common.units.yAxisMicrovolts')),
      line: new LineChart({
        container: "chart-line",
        threshold: this.threshold
      }, this.translation.instant("common.units.xAxisSeconds"),
      this.translateService.instant('common.units.yAxisMicrovolts'))
    }
    this.chart = this.charts.line;

    this.muscles.map(muscle => muscle.channel);
    if (this.stellaDirect.state.getCable()) {
      Object.values(this.charts).forEach(chart => {
        chart.setColorArray(
          COLOR_ARRAY.filter((_, i) =>
            this.stellaDirect.state.getCable().channels.includes(i)
          )
        );
      });
    }

    this.changeUnit(this.unit);
    this.changeScale(this.max);
  }


  onChartTypeChange(name: string) {
    switch (name) {
      case "bar":
        this.selectedType = ChartType.BAR;
        break;
      case "chart":
        this.selectedType = ChartType.CHART;
        break;
    }
    this.cdr.detectChanges();
  }

  trackById(_, item) {
    return item.index;
  }

}
