import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { ComponentsModule } from '@components/components.module';
import { BatteryIconComponent } from './components/battery-icon/battery-icon.component';
import { ConnectedIconComponent } from './components/connected-icon/connected-icon.component';
import { SingleStellaComponent } from './components/single-stella/single-stella.component';
import { WifiIconComponent } from './components/wifi-icon/wifi-icon.component';
import { BtConfigureComponent } from './pages/bt-configure/bt-configure.component';
import { ListStellasComponent } from './pages/list-stellas/list-stellas.component';
import { StellaRoutingModule } from './stella-routing.module';
import { StellaComponent } from './stella.component';
import { MatMenuModule } from '@angular/material/menu';
import { BatteryPipe } from './pipes/battery.pipe';
import { StellaNameComponent } from './components/stella-name/stella-name.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    StellaComponent,
    SingleStellaComponent,
    WifiIconComponent,
    BatteryIconComponent,
    BtConfigureComponent,
    ListStellasComponent,
    ConnectedIconComponent,
    BatteryPipe,
    StellaNameComponent
  ],
  entryComponents: [
    StellaNameComponent
  ],
  imports: [
  StellaRoutingModule,
    CommonModule,
    MatProgressBarModule,
    MatRippleModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    ComponentsModule,
    MatMenuModule,
    MatCheckboxModule
  ],
  exports: [WifiIconComponent, BatteryIconComponent, ConnectedIconComponent]
})
export class StellaModule { }
