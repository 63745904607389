import * as Chart from 'chart.js';
import { BODY_MODELS } from '@app/bodyModels';
import { BodyPart } from '@app/enums';
import { Clinic } from '@app/models/Clinic.model';
import { Gender } from '@app/models/Gender.enum';
import { Exercise } from './models/Exercise.model';

export interface ID {
  id: number;
}
export interface DiagnosticTestEvent {
  type?: MovementType;
  value?: number;
  event: TestEventType;
  time?: number;
  detail?: ContractionType;
  line?: any;
  lines?: any[];
}

export interface IChartController {
  exerciseTime: number;
  chart: any;
  addData(data: any): any;
  updateChannel(channel: number): void;
  getExerciseTimePerChannel(channel: number): number;
}

export interface ChannelAverage {
  channel: number;
  average: number;
}

export interface SoundInfo {
  type: MovementType;
  details: ContractionType;
}

export interface ElectrostimTestEvent {
  value?: number;
  event: TestEventType;
  time?: number;
  detail?: any;
  line?: any;
  lines?: any[];
  channel?: number;
}


export interface SoundPlayerData {
  type: SoundPlayerType | MovementType;
  details: string | ContractionType;
}

export enum SoundPlayerType {
  RELAX_SOUND = 'relax',
  CONTRACTION_SOUND = 'contraction'
}

export interface LoginInfo {
  email: string;
  date: Date;
  avatar?: string;
  gender?: Gender;
  id: Identifier;
}

export interface MarkedMuscle {
  selector: string;
  channel?: number;
  blink?: boolean;
}

export interface ChartLine {
  type: 'line';
  mode: 'vertical' | 'horizontal';
  scaleID: string;
  value: number;
  borderColor: string;
  borderWidth: number;
}

export interface ReportCalculateData {
  surroundLimit;
  partSignal;
  step: number;
  threshold?: number;
}

export interface LineConfigurationOption {
  startTime?: number;
  max?: number;
  lineCreator?: (type: string, time: number) => ChartLine;
}

export interface DiagnosticTestPort {
  channel: number;
  mvc: number;
}


export interface DiagnosticTestConfiguration {
  ports: DiagnosticTestPort[];
  threshold: number;
  steps: BiofeedbackStepConfiguration[];
  eventPreTime: number;
}

export interface GlazerParams {
  threshold: number;
  relaxation: any;
  flicks: any;
  endurance: any;
  workRest: any;
  final: any;
}

export interface BiofeedbackParams {
  activation: any;
  repetition: any;
}

export class BiofeedbackEmptyParams implements BiofeedbackParams {
  activation: {};
  repetition: {};
}

export class GlazerEmptyParams implements GlazerParams {
  threshold: number;
  relaxation: any;
  flicks: {
    work: any;
    rest: any;
    all?: any;
  };
  endurance: {
    work: any;
    rest: any;
    all?: any;
  };
  workRest: {
    work: any;
    rest: any;
    all?: any;
  };
  final: any;

}

export interface SelectedMuscle {
  bodyModel: any;
  selected: boolean;
  channel: number;
  side: 'L' | 'R' | 'M';
  quality?: any;
  purpose: "primary" | "trigger" | "both"
}

export interface ChartPoint {
  x: number;
  y: number;
}

export interface GuideChartPoint extends ChartPoint {
  g: number;
}

export interface ExtendedSignalPoint extends GuideChartPoint {
  r: number;
}

export interface IBodyPart {
  value: string;
  label: string;
  icon: string;
  count: number;
}

export const PARTS: IBodyPart[] = [
  {
    value: 'all',
    label: 'parts.showAll',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_calosc_pelna.png', count: BODY_MODELS.length
  },
  {
    value: 'head',
    label: 'parts.head',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_glowa.png', count: BODY_MODELS.filter(pp => pp.part === 'head').length
  },
  {
    value: 'upper',
    label: 'parts.upper',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_konczyny_gorne.png', count: BODY_MODELS.filter(pp => pp.part === 'upper').length
  },
  {
    value: 'lower',
    label: 'parts.lower',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_konczyny_dolne.png', count: BODY_MODELS.filter(pp => pp.part === 'lower').length
  },
  {
    value: 'pectoral',
    label: 'parts.pectoral',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_tulow_przod_01.png', count: BODY_MODELS.filter(pp => pp.part === 'pectoral').length
  },
  {
    value: 'abdominal',
    label: 'parts.abdominal',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_tulow_przod_01.png', count: BODY_MODELS.filter(pp => pp.part === 'abdominal').length
  },
  {
    value: 'back',
    label: 'parts.back',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_tulow_01.png', count: BODY_MODELS.filter(pp => pp.part === 'back').length
  },
  {
    value: 'pelvic',
    label: 'parts.pelvic',
    icon: '/assets/png/parts/EGZOTech_StellaBIO_ikony_oznaczenia_partii_dno_miednicy.png', count: BODY_MODELS.filter(pp => pp.part === 'pelvic').length
  }
];

export interface MinMax {
  min: number;
  max: number;
}

export interface ChannelMuscleMapperValidEvent {
  muscles?: SelectedMuscle[];
  wellChannels?: SelectedMuscle[];
  res?: any;
}

export interface CableDetails {
  code: number;
  channels: number[];
  description: {
    '*': string;
    slug: string;
  };
  internal: number[];
}

export enum Current {
  BIPHASIC_SYMMETRIC = 'BIPHASIC_SYMMETRIC',
  BIPHASIC_ASYMMETRIC = 'BIPHASIC_ASYMMETRIC'
}

export enum CurrentType {
  RECTANGULAR = 'RECTANGULAR',
  TRIANGULAR = 'TRIANGULAR',
  TRAPEZOIDAL = 'TRAPEZOIDAL',
  SINUS = 'SINUS',
  TENS = 'TENS'
}

export interface ElectrostimTest {
  id: string;
  name: string;
  short?: string;
  slug?: string;
  details: {
    current: Current;
    currentType: CurrentType;
    currentRange: MinMax;
    pulseDuration: number;
    workTime: number;
    restTime: number;
    rampUp: number;
    rampDown: number;
    frequency: number;
    channelsAmount: number;
    duration: number;
  };
  tags: string[];
  bodyPart: BodyPart;
}

export interface BiofeedbackStepConfiguration {
  type?: ContractionType;
  workTime: number;
  restTime: number;
  repetitions: number;
  threshold: number;
  removable?: boolean;
  duration?: number;
  endTime?: number;
  startRelaxDuration?: number;
}

export enum SidenavExtraEvents {
  SHOW_DIAGNOSTIC = 'SHOW_DIAGNOSTIC',
  SHOW_ELECTROSTIM = 'SHOW_ELECTROSTIM',
  SHOW_GAMES = 'SHOW_GAMES'
}

export enum ExtraShowMode {
  HIDDEN = 'HIDDEN',
  DIAGNOSTIC = 'DIAGNOSTIC',
  ELECTROSTIM = 'ELECTROSTIM',
  GAMES = 'GAMES'
}

export enum MovementType {
  CONTRACTION = 'contraction',
  RELAX = 'relax'
}

export enum ContractionType {
  HOLD = 'hold',
  FLICK = 'flick',
  NORMAL = 'normal'
}

export enum TestEventType {
  SOUND = 'sound',
  THRESHOLD = 'threshold',
  LINE = 'line',
  LINES_UPDATE = 'lines_update',
  END = 'end',
  STIM_START = 'stim_start',
  STIM_STOP = 'stim_stop',
  STIM_PRE_START = 'ramp_up',
  STIM_POST_STOP = 'ramp_down',
  PHASE_START = 'phase_start'
}

export interface SessionMemory {
  clinic: Partial<Clinic>;
  completed: number;
  endDate?: Date;
  id?: number;
  patient: any;
  startDate: Date;
  therapist: any;
  uploaded: number;
  extras?: any;
  remoteId?: string;
}

export interface Battery {
  cur: number;
  pow: number;
  tem: number;
  vol: number;
  level: number;
  low: number;
  high: number;
  notified: boolean;
}

export interface MeetingMemory {
  completed: number;
  endDate?: Date;
  id?: number;
  muscles: SelectedMuscle[];
  name: string;
  session: number;
  startDate: Date;
  threshold: number[];
  uploaded: number;
  remoteId?: string;
  report?: any;
  results: any;
  module?: string;
  cable: CableDetails;
  extras?: {
    intensityChanges?: {
      channel: number;
      time: number;
      level: number;
    }[];
    newTitle?: string;
    repetitions?: number;
    duration?: number;
    timeBreaks?: number[];
    channels?: number[];
    concept?: string;
    cable?: CableDetails;
    primary?: number;
    secondary?: number;
    emgCalibration?: number[];
    emsCalibration?: any;
    template?: Exercise;
    headerTitles?: Record<string,string>;
  };
  intensityChanges?: {
    channel: number;
    level: number;
    time: number;
  }[];
  duration?: number;
  programCalibration?: {
    maxDuration?: number;
    frequency?: number;
    pulseDuration?: number;
    pulseShape?: CurrentType;
    ramps?: {
      rampDown: number;
      rampUp: number;
    }
  },
  repetitions?: number;
  newTitle?: string;
  timeBreaks?: number[];
}

export interface ChunkMemory {
  rmss: SignalPack;
  guideLine: SignalPack;
  channels?: SignalPack;
  rmssCompressed?: CompressedPack;
  guideLineCompressed?: CompressedPack;
  channelsCompressed?: CompressedPack;
}

export interface Signals {
  rmss: SignalPack | string[];
  guideLine: SignalPack | string[];
  channels: SignalPack | string[];
  // rmss, guideLine and channels data is an array, indices in these arrays are
  // not representing channel number, data is added to arrays in the order of
  // downloading resource URLs, mapping form this array to channel number can be
  // found using `muscles` property
  rmssData?: Float32Array[];
  guideLineData?: Float32Array[];
  channelsData?: Float32Array[];

  rmssCompressed?: CompressedPack;
  guideLineCompressed?: CompressedPack;
  channelsCompressed?: CompressedPack;
}

export interface MeetingWithSignals extends MeetingMemory, Signals {
  expanded?: boolean;
  decimatedRmss?: ChartPoint[][];
  decimatedGuideLine?: ChartPoint[][];
  template?: Exercise & { report: string };
}

export interface SessionWithMeetings extends SessionMemory {
  meetings: MeetingMemory[];
}

export interface ChannelSignal {
  [key: string]: Float32Array;
}

export interface Line {
  type: MovementType | 'END';
  time: number;
  value: number;
}

export interface Change {
  type?: MovementType;
  time: number;
  value?: number;
  event?: TestEventType;
  detail?: any;
  emitted?: boolean;
}

export interface IdEntity<T> {
  id: T;
}

export interface SimpleValue<T> {
  value: T;
}

export interface Passwords {
  password: string;
  passwordConfirmation: string;
}

export type EditableTemplate = Required<IdEntity<string>>;

export interface SessionTemplate {
  name: string;
}

export interface SignalPack {
  [key: number]: Float32Array;
}

export interface CompressedPack {
  [key: number]: Uint8Array;
}

export interface CreateInvitation {
  email: string;
  role: number[];
}

export interface ProcessInvitation {
  id: Identifier;
  result: boolean;
}
export interface TokenProcessInvitation extends ProcessInvitation {
  token: string;
}

export interface ProcessUserInvitation extends ProcessInvitation {
  role: any;
  clinic: string;
}
export type MinimalClinic = Partial<Clinic> & Required<{ id: string }>;

export interface Notification {
  type: 'error' | 'info' | 'success';
  message: string;
  action: string;
}

export class StimChannelRequest {
  private mux = 0;
  private program: TestStimPrograms;
  per: number;
  duration: number;
  count: number;
  amplitude: number;
  data: number[];

  createMessage(): StimChannelMessage {
    return {
      mux: this.mux,
      prg: this.program,
      per: this.per,
      dur: this.duration,
      cnt: this.count,
      amp: this.amplitude,
      dat: this.data
    };
  }
}

export interface StimChannelMessage {
  mux: number;
  prg: number;
  per: number;
  dur: number;
  cnt: number;
  amp: number;
  dat: number[];
}

export enum TestStimPrograms {
  STUB = 0,
  RAW = 1,
  SIXTY_TWO = 2
}

export interface EmgMessage {
  d: SignalPack;
  i: number[];
  chinfo: { [key: number]: ChannelInfo };
}

export interface ChannelInfo {
  imp: number;
}

export interface Supply {
  main: boolean;
  stim: boolean;
}

export interface Periph {
  ads: Ads;
}

export interface Device {
  os: Os;
}

export interface Os {
  name: string;
  version: number | string;
}

export interface Ads {
  ena: boolean;
}

export interface NetScan {
  ssid: string;
  ch: number;
  auth: any;
  rrsi: number;
}

export interface StellaRequest {
  data: SmartledRequest;
}

export interface SmartledRequest {
  smartled: Uint32Array;
}

export interface BluetoothWifiData {
  ssid: string;
  pass: string;
  bssid?: string;
}

export type Identifier = number | string;

export interface License {
  createdAt: string;
  endDate: string;
  extras: any;
  id: number;
  name: string;
  updatedAt: string;
  version: number;
}

export type FunctionalCategory = 'pelvic' | 'emg' | 'fes' | 'emg_triggered' | 'ems' | 'tens' | 'game';

export type ExerciseType = 'diagnostic' | 'custom' | 'games' | 'fes' | 'electrostim';

export interface ReportChannel {
  index: number,
  channel: number,
  params: {
    work?: any;
    rest?: any;
    all?: any;
  },
  muscle: SelectedMuscle,
}

export interface ChartColor {
  primary: string,
  secondary: string
}

export interface ChartState {
  hiddenChannels: boolean[];
  chart: Chart;
}

export type PercentageComparison = Partial<Record<'1-2'|'3-4'|'5-6'|'7-8', { avgCmp: number, maxCmp: number }>>

export interface ChannelCalculationParameters {
  rest?: {
    avg: number;
    min: number;
    max: number;
    deviation: number;
    coef: number;
    threshold?: number;
  };
  work?: {
    avg: number;
    min: number;
    max: number;
    deviation: number;
    coef: number;
  };
  all?: {
    onset: number;
    release: number;
    activationTime?: number;
    relaxationTime?: number;
    linearRegression?: number;
    confirmedContractions: ComplexContractions[];
  };
}

export interface InitialRestCalculationParameters {
  average: number;
  deviation: number;
  threshold: number;
}

export interface ExerciseCalculationsChannel<T = ChannelCalculationParameters> {
  index: number;
  channel: number;
  params: T;
  muscle: SelectedMuscle;
  signal?: ExtendedSignalPoint[];
  mvc?: number;
}

export interface ExerciseCalculations {
  channels: ExerciseCalculationsChannel[],
  percentageComparison: PercentageComparison
}

export type SignalElement = { x: number; y: number; type: "positive" | "negative" };

export interface SimpleContractions {
  positive: SignalElement;
  negative: SignalElement;
}

export interface ComplexContractions extends SimpleContractions {
  edges: SignalElement[];
  exact: Omit<SignalElement, "type">[];
}

export interface MeetingWithCalculations extends MeetingWithSignals {
  params?: { [key: number]: ChannelCalculationParameters };
  percentageComparison?: PercentageComparison;
}

export interface NewExerciseCalculationsSummary {
  type?: "contractions" | "rest" | "quickflicks";
  name?: string;
  range?: readonly [number, number];
  timeRange?: readonly [number, number];
  displayRange?: readonly [number, number];
}

export interface NewExerciseCalculationsAnalyzeRange {
  type: "contraction" | "rest";
  name?: string;
  hidden?: boolean;
  range: readonly [number, number] ;
}

export interface NewExerciseCalculationsArguments {
  rmssData: Float32Array;
  guideLineData: Float32Array;
  initialRestTime: number;
  finalRestTime?: number;
  primaryChannel?: number;
  displayRanges?: (readonly [number, number])[];
  ranges: ((readonly [number, number]) | NewExerciseCalculationsAnalyzeRange)[];
  generateRangesFromGuideLine: boolean;
  summaries: NewExerciseCalculationsSummary[];
  channel?: number;
}

export type NewExerciseCalculationsResult = {
  type: "decimation";
  data: SignalDecimationWorkerResult;
} | {
  type: "partial";
  data: NewExerciseCalculations;
} | {
  type: "end";
  data: {
    rmssData: Float32Array;
    guideLineData: Float32Array;
    results: NewExerciseCalculations;
  };
} | {
  type: "progress";
  value: number;
} | {
  type: "error"
}

export interface NewExerciseCalculationsRange {
  // range: readonly [number, number];
  percentageComparison: PercentageComparison;
  channels: {
    [key: number]: ChannelCalculationParameters;
  };
}

export interface NewExerciseCalculations {
  ranges: NewExerciseCalculationsRange[];
  summaries: {
    range: readonly [number, number];
    percentageComparison: PercentageComparison;
    channels: {
      [key: number]: ChannelCalculationParameters;
    };
  }[];
  initialRest: {
    channels: {
      [key: number]: InitialRestCalculationParameters
    };
  };
}

export interface SignalDecimationWorkerArguments {
  exercise: MeetingWithCalculations;
  initialRestTime: number;
  ranges: (readonly [number, number])[];
  summaries: NewExerciseCalculationsSummary[];
}

interface SignalDecimationWorkerRangeResult {
  range: readonly [number, number];
  name?: string;
  hidden: boolean;
  decimatedRmss: { x: Float32Array, y: Float32Array };
  decimatedGuideLine: { x: Float32Array, y: Float32Array };
}

interface SignalDecimationWorkerSummaryResult {
  range: readonly [number, number];
  name?: string;
  ranges: SignalDecimationWorkerRangeResult[];
  decimatedRmss: { x: Float32Array, y: Float32Array };
  decimatedGuideLine: { x: Float32Array, y: Float32Array };
}

export interface SignalDecimationWorkerResult {
  ranges: SignalDecimationWorkerRangeResult[];
  summaries: SignalDecimationWorkerSummaryResult[];
  decimatedRmss: { x: Float32Array, y: Float32Array };
  decimatedGuideLine: { x: Float32Array, y: Float32Array };
}

interface SignalDecimationWorkerRangeResultCombined {
  range: readonly [number, number];
  name?: string;
  hidden: boolean;
  decimatedRmss: { [key: number]: ChartPoint[] };
  decimatedGuideLine: { [key: number]: ChartPoint[] };
}

interface SignalDecimationWorkerSummaryResultCombined {
  range: readonly [number, number];
  name?: string;
  decimatedRmss: { [key: number]: ChartPoint[] };
  decimatedGuideLine: { [key: number]: ChartPoint[] };
}

export interface SignalDecimationWorkerResultCombined {
  ranges: SignalDecimationWorkerRangeResultCombined[];
  summaries: SignalDecimationWorkerSummaryResultCombined[];
  decimatedRmss: { [key: number]: ChartPoint[] };
  decimatedGuideLine: { [key: number]: ChartPoint[] };
}


export interface ReportNorm {
  min?: number;
  max?: number;
  type: "inlcusive" | "exclusive";
  unit: "uV" | "%" | "s";
}

export type ChartUnit = 'pctmvc' | 'microvolts';