<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">{{ "stimSetting.title" | translate }}</h2>
    <div class="outline-button" (click)="confirm()">
      {{ "training.acceptSettings" | translate }}
    </div>
  </div>
  <div class="subtitle">{{ "stimSetting.training_settings" | translate }}</div>
  <div class="controls-row" *ngIf="program.parameters" [ngClass]="{'disabled': isAlreadySet}">
    <mat-form-field *ngIf="program.parameters.phaseRepetition">
      <mat-label>{{ "stimSetting.repetitions" | translate }}</mat-label>
      <mat-select
        [value]="parameters.phaseRepetition !== undefined ? parameters.phaseRepetition : program.parameters.phaseRepetition.default"
        (selectionChange)="changeVal($event, 'phaseRepetition')"
      >
        <mat-select-trigger>
          {{ parameters.phaseRepetition !== undefined ?
            parameters.phaseRepetition + generateProgramDurationInfo(parameters.phaseRepetition) :
            program.parameters.phaseRepetition.default + generateProgramDurationInfo(program.parameters.phaseRepetition.default) }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let rep of program.parameters.phaseRepetition.values"
          [value]="rep"
          innerHtml="{{ rep }}{{ generateProgramDurationInfo(rep) }}"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngFor="let phase of dynamicProgramParameters.phases; index as i">
      <mat-form-field *ngIf="phase && phase.channels && phase.channels.runTime">
        <mat-label>{{ "stimSetting.treatmentDuration" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.runTime !== undefined ? parameters.phases[i].channels.runTime : phase.channels.runTime.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.runTime')"
        >
          <mat-select-trigger>
            {{ (parameters.phases[i].channels.runTime !== undefined ? parameters.phases[i].channels.runTime : phase.channels.runTime.default) / (60 * 1e6) + durationModifier}} {{ 'common.units.minutes' | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let rt of phase.channels.runTime.values"
            [value]="rt.value"
            innerHtml="{{ rt.value / (60 * 1e6) + durationModifier }} {{ 'common.units.minutes' | translate }}"
            disabled="{{ rt.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.channels && phase.channels.pulseWidth">
        <mat-label>{{ "stimSetting.pulseDuration" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.pulseWidth !== undefined ? parameters.phases[i].channels.pulseWidth : phase.channels.pulseWidth.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.pulseWidth')"
        >
          <mat-select-trigger>
            {{ parameters.phases[i].channels.pulseWidth !== undefined ? parameters.phases[i].channels.pulseWidth : phase.channels.pulseWidth.default }} {{ "common.units.microseconds" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let pw of phase.channels.pulseWidth.values"
            [value]="pw.value"
            innerHtml="{{ pw.value }} {{ 'common.units.microseconds' | translate }}"
            disabled="{{ pw.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.channels && phase.channels.pulseFrequency">
        <mat-label>{{ "stimSetting.frequency" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.pulseFrequency !== undefined ? parameters.phases[i].channels.pulseFrequency : phase.channels.pulseFrequency.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.pulseFrequency')"
        >
          <mat-select-trigger>
            {{ parameters.phases[i].channels.pulseFrequency !== undefined ? parameters.phases[i].channels.pulseFrequency : phase.channels.pulseFrequency.default }} {{ "common.units.hertz" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let fq of phase.channels.pulseFrequency.values"
            [value]="fq.value"
            innerHtml="{{ fq.value }} {{ 'common.units.hertz' | translate }}"
            disabled="{{ fq.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.channels && phase.channels.riseTime">
        <mat-label>{{ "stimSetting.rampUp" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.riseTime !== undefined ? parameters.phases[i].channels.riseTime : phase.channels.riseTime.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.riseTime')"
        >
          <mat-select-trigger>
            {{ (parameters.phases[i].channels.riseTime !== undefined ? parameters.phases[i].channels.riseTime : phase.channels.riseTime.default) / 1000000 }} {{ "common.units.seconds" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let rup of phase.channels.riseTime.values"
            [value]="rup.value"
            innerHtml="{{ rup.value / 1000000 }} {{ 'common.units.seconds' | translate }}"
            disabled="{{ rup.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.channels && phase.channels.fallTime">
        <mat-label>{{ "stimSetting.rampDown" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.fallTime !== undefined ? parameters.phases[i].channels.fallTime : phase.channels.fallTime.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.fallTime')"
        >
          <mat-select-trigger>
            {{ (parameters.phases[i].channels.fallTime !== undefined ? parameters.phases[i].channels.fallTime : phase.channels.fallTime.default) / 1000000 }} {{ "common.units.seconds" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let rd of phase.channels.fallTime.values"
            [value]="rd.value"
            innerHtml="{{ rd.value / 1000000 }} {{ 'common.units.seconds' | translate }}"
            disabled="{{ rd.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.channels && phase.channels.plateauTime">
        <mat-label>{{ "stimSetting.workTime" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.plateauTime !== undefined ? parameters.phases[i].channels.plateauTime : phase.channels.plateauTime.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.plateauTime')"
        >
          <mat-select-trigger>
            {{ (parameters.phases[i].channels.plateauTime !== undefined ? parameters.phases[i].channels.plateauTime : phase.channels.plateauTime.default) / 1000000 }} {{ "common.units.seconds" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let wt of phase.channels.plateauTime.values"
            [value]="wt.value"
            innerHtml="{{ wt.value / 1000000 }} {{ 'common.units.seconds' | translate }}"
            disabled="{{ wt.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.channels && phase.channels.pauseTime">
        <mat-label>{{ "stimSetting.restTime" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].channels.pauseTime !== undefined ? parameters.phases[i].channels.pauseTime : phase.channels.pauseTime.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.channels.pauseTime')"
        >
          <mat-select-trigger>
            {{ (parameters.phases[i].channels.pauseTime !== undefined ? parameters.phases[i].channels.pauseTime : phase.channels.pauseTime.default) / 1000000 }} {{ "common.units.seconds" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let rt of phase.channels.pauseTime.values"
            [value]="rt.value"
            innerHtml="{{ rt.value / 1000000 }} {{ 'common.units.seconds' | translate }}"
            disabled="{{ rt.disabled }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="phase && phase.endRelaxTime">
        <mat-label>{{ "stimSetting.restTime" | translate }}</mat-label>
        <mat-select
          [value]="parameters.phases[i].endRelaxTime !== undefined ? parameters.phases[i].endRelaxTime : phase.endRelaxTime.default"
          (selectionChange)="changeVal($event, 'phases.' + i + '.endRelaxTime')"
        >
          <mat-select-trigger>
            {{ (parameters.phases[i].endRelaxTime !== undefined ? parameters.phases[i].endRelaxTime + getPauseTime(i) : phase.endRelaxTime.default + getPauseTime(i)) }} {{ "common.units.seconds" | translate }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let rt of phase.endRelaxTime.values"
            [value]="rt"
            innerHtml="{{ rt + getPauseTime(i) }} {{ 'common.units.seconds' | translate }}"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <mat-slide-toggle
      *ngIf="program.adjustable.warmup"
      [checked]="program.details.warmup"
      (change)="changeVal({ value: $event.checked }, 'warmup')"
    >
      {{ "stimSetting.warmup" | translate }}
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="program.adjustable.cooldown"
      [checked]="program.details.cooldown"
      (change)="changeVal({ value: $event.checked }, 'cooldown')"
    >
      {{ "stimSetting.cooldown" | translate }}
    </mat-slide-toggle> -->
  </div>
  <div *ngIf="isEMGTriggered" class="channels-row">
    <div class="subtitle">{{ "stimSetting.channel_purpose_settings" | translate }}</div>
    <div class="channel-purpose-selector">
      <div class="channel-purpose-item" *ngFor="let channel of channels">
        <div
          [ngStyle]="{
            backgroundColor: channel.channel | channelcolor
          }"
          class="badge"
        >
          {{ channel.channel + 1 }}
        </div>
        <mat-form-field>
          <mat-select
            [value]="channel.purpose"
            (selectionChange)="changeChannelPurpose($event, channel)"
          >
            <mat-option *ngIf="channel.purpose === 'primary'"
              [value]="'primary'"
            >{{ 'stimSetting.channelPurpose.primary' | translate }}
            </mat-option>
            <mat-option *ngIf="data.allowEmgChannels"
              [value]="'trigger'"
            > {{ 'stimSetting.channelPurpose.trigger' | translate }}
            </mat-option>
            <mat-option *ngIf="data.allowEmgEmsChannels"
              [value]="'both'"
            > {{ 'stimSetting.channelPurpose.both' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="deny-button--container">
    <button mat-button class="deny-button" (click)="deny()">
      {{ "training.back_to_muscle" | translate }}
    </button>
  </div>
</mat-dialog-content>
