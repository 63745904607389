import { Howl } from 'howler';
import { Sounds } from '@app/enums';
import { ContractionType, SoundPlayerData, SoundPlayerType } from '@app/types';

export class Player {
    sounds = new Sounds();

    play(data: SoundPlayerData, lang: string): void {
        const sound = new Howl({
            src: [this.getSoundForEvent(data, lang)],
            html5: true
        });
        sound.play();
    }

    private getSoundForEvent(data: SoundPlayerData, lang: string): string {
        const fallbackLang = 'en';
        if (data.type === SoundPlayerType.RELAX_SOUND) {
            return this.sounds.RELAX[lang] ? this.sounds.RELAX[lang] : this.sounds.RELAX[fallbackLang]
        } else {
            switch (data.details) {
                case ContractionType.FLICK:
                    return this.sounds.FLICK_CONTRACTION[lang] ? this.sounds.FLICK_CONTRACTION[lang] : this.sounds.FLICK_CONTRACTION[fallbackLang];
                case ContractionType.NORMAL:
                    return this.sounds.NORMAL_CONTRACTION[lang] ? this.sounds.NORMAL_CONTRACTION[lang] : this.sounds.NORMAL_CONTRACTION[fallbackLang];
                case ContractionType.HOLD:
                    return this.sounds.HOLD_CONTRACTION[lang] ? this.sounds.HOLD_CONTRACTION[lang] : this.sounds.HOLD_CONTRACTION[fallbackLang];
            }
        }
    }
}
