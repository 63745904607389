import { CableDetails } from '@app/types';
import { ChartUnit } from '@app/types';
export const ACCESS_TOKEN_KEY = 'STELLA_ACCESS_TOKEN_KEY';
export const STELLA_CURRENT_STELLA = 'STELLA_CURRENT_STELLA';
export const STELLA_CURRENT_CLINIC = 'STELLA_CURRENT_CLINIC';
export const STELLA_CURRENT_PATIENT = 'STELLA_CURRENT_PATIENT';
export const STELLA_LAST_SESSION = 'STELLA_LAST_SESSION';
export const STELLA_CURRENT_USER = 'STELLA_CURRENT_USER';
export const STELLA_LAST_CHART_SCALE = 'STELLA_LAST_CHART_SCALE';
export const STELLA_LAST_CHART_PCT_SCALE = 'STELLA_LAST_CHART_PCT_SCALE';
export const STELLA_LAST_UNIT = 'STELLA_LAST_UNIT';
export const STELLA_EMG_LAST_MASK = 'STELLA_EMG_LAST_MASK';
export const LAST_LOGGED_IN = 'LAST_LOGGED_IN';
export const DEFAULT_STELLA_HOSTNAME = 'stella-direct';
export const DEFAULT_STELLA_WSS_PORT = '8443';
export const DEFAULT_STELLA_CERT_LOCATION = 'etc/egzotech-selfsigned.crt';
export const CUSTOM_EXERCISE_TEMPLATE = 10;
export const LAST_ACCEPTED_VERSION = 'LAST_ACCEPTED_VERSION';

export const MINIMUM_STELLA_OS_VERSION = 13004;

export const MAX_INT16 = Math.pow(2, 15) - 1;

export const BATTERY_MAX = 8.1;
export const BATTERY_MIN = 7.0;

export enum DashboardMode {
  PATIENT = 'patient',
  SPECIALIST = 'specialist',
  OWNER = 'owner'
}
export class Sounds {
  public FLICK_CONTRACTION = {
    en: '/assets/sounds/en/QuickFlick.mp3',
    pl: '/assets/sounds/pl/Contract.mp3',
    de: '/assets/sounds/de/Contract.mp3',
    cs: '/assets/sounds/cs/Contract.mp3',
    uk: '/assets/sounds/uk/Contract.mp3',
    zh_Hant:'/assets/sounds/zh_Hant/Contract.mp3',
    zh_Hans:'/assets/sounds/zh_Hans/Contract.mp3'

  };
  public RELAX = {
    en: '/assets/sounds/en/Relax.mp3',
    pl: '/assets/sounds/pl/Relax.mp3',
    de: '/assets/sounds/de/Relax.mp3',
    cs: '/assets/sounds/cs/Relax.mp3',
    uk: '/assets/sounds/uk/Relax.mp3',
    zh_Hant:'/assets/sounds/zh_Hant/Relax.mp3',
    zh_Hans:'/assets/sounds/zh_Hans/Relax.mp3'
  };
  public NORMAL_CONTRACTION = {
    en: '/assets/sounds/en/Contract.mp3',
    pl: '/assets/sounds/pl/Contract.mp3',
    de: '/assets/sounds/de/Contract.mp3',
    cs: '/assets/sounds/cs/Contract.mp3',
    uk: '/assets/sounds/uk/Contract.mp3',
    zh_Hant:'/assets/sounds/zh_Hant/Contract.mp3',
    zh_Hans:'/assets/sounds/zh_Hans/Contract.mp3'
  };
  public HOLD_CONTRACTION = {
    en: '/assets/sounds/en/ContractAndHold.mp3',
    pl: '/assets/sounds/pl/ContractAndHold.mp3',
    de: '/assets/sounds/de/ContractAndHold.mp3',
    cs: '/assets/sounds/cs/ContractAndHold.mp3',
    uk: '/assets/sounds/uk/ContractAndHold.mp3',
    zh_Hant:'/assets/sounds/zh_Hant/ContractAndHold.mp3',
    zh_Hans:'/assets/sounds/zh_Hans/ContractAndHold.mp3'
  };
}

export enum ClinicStatus {
  INCOMPLETE = 'incomplete',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  ACCEPTED = 'accepted'
}

export enum StellaConnectionStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected'
}

export const SCALES_UV = [
  { value: 10, label: '10 &#181;V' },
  { value: 50, label: '50 &#181;V' },
  {
    value: 100,
    label: '100 &#181;V'
  },
  {
    value: 500,
    label: '500 &#181;V'
  },
  {
    value: 1000,
    label: '1000 &#181;V'
  },
  {
    value: 5000,
    label: '5000 &#181;V'
  }
];

export const SCALES_PCT = [
  { value: 0.25, label: "25%" },
  { value: 0.5, label: "75%" },
  { value: 1.25, label: "125%" }
];

interface Unit {
  value: ChartUnit;
  label: string;
}

export const UNITS: Unit[] = [
  { value: "pctmvc", label: "" },
  { value: "microvolts", label: "" },
];

export const FRONT_MUSCLES = [
  'tibialis-anterior-L',
  'peroneus-longus-L',
  'vastus-medialis-L',
  'adductores-L',
  'vastus-lateralis-L',
  'rectus-femoris-L',
  'tensor-fascia-latae-L',
  'iliopsoas-L',
  'tibialis-anterior-R',
  'peroneus-longus-R',
  'vastus-medialis-R',
  'adductores-R',
  'vastus-lateralis-R',
  'rectus-femoris-R',
  'tensor-fascia-latae-R',
  'iliopsoas-R',
  'flexo-carpi-radialis-L',
  'brachioradialis-L',
  'biceps-brachii-L',
  'deltoideus-anterior-L',
  'deltoideus-medium-L',
  'flexo-carpi-radialis-R',
  'brachioradialis-R',
  'biceps-brachii-R',
  'deltoideus-anterior-R',
  'deltoideus-medium-R',
  'frontalis-L',
  'masseter-L',
  'sternocleidomastoideus-L',
  'frontalis-R',
  'masseter-R',
  'sternocleidomastoideus-R',
  'transversus-abdominis-L',
  'obliquus-externus-abdominis-L',
  'rectus-abdominis-2-L',
  'rectus-abdominis-1-L',
  'serratus-anterior-L',
  'pectoralis-major-L',
  'transversus-abdominis-R',
  'obliquus-externus-abdominis-R',
  'rectus-abdominis-2-R',
  'rectus-abdominis-1-R',
  'serratus-anterior-R',
  'pectoralis-major-R',
  'extensor-digitorum-R',
'extensor-pollicis-longus-R',
'flexor-digitorum-superficialis-R',
'flexor-pollicis-longus-R',
'pronator-teres-R',
  'extensor-digitorum-L',
  'extensor-pollicis-longus-L',
  'flexor-digitorum-superficialis-L',
  'flexor-pollicis-longus-L',
  'pronator-teres-L',
];

export const PELVIC_MUSCLES = ['diaphragma-pelvis'];

export const BACK_MUSCLES = [
  'erector-spinae-iliocostalis-R',
  'triceps-brachii-c-long-R_2_',
  'latissimus-dorsi-R_2_',
  'erector-spinae-longissimus-R_2_',
  'triceps-brachii-c-lat-R_2_',
  'trapezius-ascendens-R_2_',
  'infraspinatus-R_2_',
  'multifidus-R_2_',
  'biceps-femoris-R_2_',
  'semitendinosus-R_2_',
  'gastrocnemius-c-med-R_4_',
  'gastrocnemius-c-med-R_2_',
  'peroneus-R_2_',
  'soleus-R_2_',
  'gluteus-medius-R_2_',
  'trapezius-transversalis-R_2_',
  'gluteus-maximus-R_2_',
  'neck-extensors-R_2_',
  'trapezius-descendes-R_2_',
  'deltoideus-posterior-R_2_',
  'triceps-brachii-c-long-L',
  'latissimus-dorsi-L',
  'erector-spinae-longissimus-L',
  'triceps-brachii-c-lat-L',
  'trapezius-ascendens-L',
  'infraspinatus-L',
  'multifidus-L',
  'biceps-femoris-L',
  'semitendinosus-L',
  'gastrocnemius-c-med-L_1_',
  'gastrocnemius-c-med-L',
  'peroneus-L',
  'soleus-L',
  'gluteus-medius-L',
  'trapezius-transversalis-L',
  'gluteus-maximus-L',
  'neck-extensors-L',
  'trapezius-descendes-L_1_',
  'deltoideus-posterior-L_1_',
  'erector-spinae-iliocostalis-L'
];

export const PAIN_MUSCLES = [
  'knee',
  'ankle',
  'shoulder',
  'elbow'
];

export const ALL_MUSCLES = [
  ...FRONT_MUSCLES,
  ...BACK_MUSCLES,
  ...PELVIC_MUSCLES,
  ...PAIN_MUSCLES
];

export const LANGUAGES = [
  { value: 'en', label: 'English (US)', flag: 'us' },
  { value: 'pl', label: 'Polski', flag: 'pl' },
  { value: 'de', label: 'Deutsch', flag: 'de' },
  { value: 'cs', label: 'Čeština', flag: 'cs' },
  { value: 'uk', label: 'Українська', flag: 'uk' },
  { value: 'zh_Hant', label: 'Cantonese', flag: 'zh_Hant' },
  { value: 'zh_Hans', label: 'Mandarin', flag: 'zh_Hans' }
];

export const CACHE_KEY_PATIENTS_LIST = 'PATIENT_LIST';

export enum SmartledProgram {
  STATIC_GREEN,
  STATIC_RED,
  STATIC_WHITE,
  STATIC_STELLA,
  OFF
}

export enum ExerciseState {
  STARTED = 'started',
  INVALID = 'invalid'
}

export enum CableType {
  EMGEMS_2_0 = 3115,
  EMGEMS_6_2 = 65295,
  EMGEMS_4_0 = 3096,
  EMGEMS_2_1 = 3108,
  EMGEMS_2_2 = 3102,
  EMGEMS_4_2 = 3087,
  EMGEMS_4_1 = 3092,
  EMGEMS_8_0 = 65290,
  NONE = 65535
}

export const Cable: {[ key: string]: CableDetails} = {
  [CableType.EMGEMS_8_0]: {
    code: CableType.EMGEMS_8_0,
    channels: [0, 1, 2, 3, 4, 5, 6, 7],
    description: {
      '*': 'Future application - walking reeducation',
      slug: 'cable.emg_ems_8_0'
    },
    internal: []
  },
  [CableType.EMGEMS_4_1]: {
    code: CableType.EMGEMS_4_1,
    channels: [0, 1, 2, 3, 6],
    description: {
      '*': 'Default Home Use Advanced for US',
      slug: 'cable.emg_ems_4_1'
    },
    internal: [6]
  },
  [CableType.EMGEMS_2_2]: {
    code: CableType.EMGEMS_2_2,
    channels: [0, 1, 6, 7],
    description: {
      '*': 'Pain + Pelvic floor Upgrade for EU, low demand',
      slug: 'cable.emg_ems_2_2'
    },
    internal: [6, 7]
  },
  [CableType.EMGEMS_4_0]: {
    code: CableType.EMGEMS_4_0,
    channels: [0, 1, 2, 3],
    description: {
      '*': 'Default Neuro/Otho/Sports cable',
      slug: 'cable.emg_ems_4_0'
    },
    internal: []
  },
  [CableType.EMGEMS_2_0]: {
    code: CableType.EMGEMS_2_0,
    channels: [0, 1],
    description: {
      '*': 'Default Pain/ TENS Home Use for EU & USA, low demand',
      slug: 'cable.emg_ems_2_0'
    },
    internal: []
  },
  [CableType.EMGEMS_6_2]: {
    code: CableType.EMGEMS_6_2,
    channels: [0, 1, 2, 3, 4, 5, 6, 7],
    description: {
      '*': 'Default Professional for EU',
      slug: 'cable.emg_ems_6_2',
    },
    internal: [6, 7]
  },
  [CableType.EMGEMS_2_1]: {
    code: CableType.EMGEMS_2_1,
    channels: [0, 1, 6],
    description: {
      '*': 'Pain + Pelvic floor Upgrade for US, low demand',
      slug: 'cable.emg_ems_2_1'
    },
    internal: [6]
  },
  [CableType.EMGEMS_4_2]: {
    code: CableType.EMGEMS_4_2,
    channels: [0, 1, 2, 3, 6, 7],
    description: {
      '*': 'Default Home Use Advanced for EU',
      slug: 'cable.emg_ems_4_2'
    },
    internal: [6, 7]
  },
  [CableType.NONE]: {
    code: CableType.NONE,
    channels: [],
    description: {
      '*': 'None',
      slug: 'cable.none'
    },
    internal: []
  }
};

export enum ConnectionQuality {
  NONE = 'none',
  POOR = 'poor',
  WELL = 'well'
}

export enum BodyPart {
  UPPER_LIMBS = 'upper',
  LOWER_LIMBS = 'lower',
  PELVIC_FLOOR = 'pelvic',
  BUTTOCKS = 'buttocks',
  BACK_MUSCLES = 'back',
  ABDOMINAL_MUSCLES = 'abdominal',
  PECTORAL_MUSCLES = 'pectoral'
}
