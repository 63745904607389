import { ChartUnit } from '@app/types';
import { Axis, AxisTickStrategies, ChartXY, ColorHEX, ConstantLine, emptyLine, SolidFill, SolidLine, Theme, translatePoint, UIBackgrounds, UIDraggingModes, UIElementBuilders, UIElementColumn, UILayoutBuilders, UIOrigins, UITextBox } from '@arction/lcjs';
import { COLOR_ARRAY } from '../utils/ColorDictionary';

export interface ChartOptions {
  container: string | HTMLDivElement;
  theme: Theme;
  showFPS: boolean;
  threshold?: number;
}

export interface ChartConfig {
  /**
   * Preload time in seconds
   */
  preloadTime: number;
  /**
   * Chart horizon span in seconds
   */
  horizont: number;
  ticks: number;
  allowShifting: boolean;
}

export class MovingTimeAxisScaleStrategy {
  start(scaleStart: number, scaleEnd: number, contentMin: number, contentMax: number): number {
    return contentMax < 15 ? 0 : contentMax - 15;
  }
  end(scaleStart: number, scaleEnd: number, contentMin: number, contentMax: number): number {
    return contentMax < 15 ? 30 : contentMax + 15;
  }
}
export abstract class EgzoChart {
  protected fpsIndicator: FPSIndicator;
  protected xAxis: Axis;
  protected yAxis: Axis;
  protected _chart: ChartXY;
  protected colorArray: string[] = COLOR_ARRAY;
  public thresholdLine: ThresholdLine;
  private strategy: ChartUnit;
  protected channelFactor: number[];

  abstract setColorArray(newArray: string[]): void;

  constructor() {
    this.resetFactors();
  }


  public setFactors(factors: number[]) {
    this.channelFactor = factors;
  }  

  public resetFactors() {
    this.setFactors(this.colorArray.map((_) => 1));
  }

  setScale(scale: { min: number; max: number }): void {
    this.yAxis.setInterval(scale.min, scale.max);
  }

  destroy() {
    try {
      this._chart.dispose();
    } catch (err) {
      console.warn("[Chart] Dispose error!");
    }
  }

  protected initializeFPSIndicator(show: boolean) {
    if (show) {
      this.fpsIndicator = new FPSIndicator(this._chart, {
        axisX: this.xAxis,
        axisY: this.yAxis,
      });
    }
  }

  setYAxisTitle(title: string) {
    this._chart.getDefaultAxisY().setTitle(title);
  }

  setYAxisStrategy(strategy: ChartUnit) {
    this.strategy = strategy;
    switch (strategy) {
      case "pctmvc":
        this._chart
          .getDefaultAxisY()
          .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
            ticks.setFormattingFunction((value) => `${(value * 100).toFixed(0)}%`)
          );
        break;
      case "microvolts":
        this._chart.getDefaultAxisY().setTickStrategy(AxisTickStrategies.Numeric,(ticks) => ticks.setFormattingFunction((value) => String(value) ));
        break;
    }
  }
}

export class FPSIndicatorOptions {
  axisX: Axis;
  axisY: Axis;
}

export class FPSIndicator {
  frameCount = 0;
  frameDelaySum = 0;
  framePrevious: number | undefined;
  pointsAdded = 0;
  displayPrevious = window.performance.now();
  constructor(chart: ChartXY, options: FPSIndicatorOptions) {
    const indicatorPos = translatePoint({
      x: options.axisX.scale.getInnerStart(),
      y: options.axisY.scale.getInnerEnd()
    }, {
      x: options.axisX.scale,
      y: options.axisY.scale
    },
      chart.uiScale
    );

    const indicatorLayout = chart.addUIElement<UIElementColumn>(
      UILayoutBuilders.Column
        .setBackground(UIBackgrounds.Rectangle),
      chart.uiScale
    )
      .setOrigin(UIOrigins.LeftTop)
      .setPosition(indicatorPos)
      .setDraggingMode(UIDraggingModes.notDraggable)
      .setBackground((background) => background
        .setFillStyle(new SolidFill({ color: ColorHEX('#000').setA(150) }))
        .setStrokeStyle(emptyLine)
      );
    const fpsPrefix = 'FPS';
    const indicatorFPS = indicatorLayout.addElement<UITextBox>(UIElementBuilders.TextBox)
      .setText(fpsPrefix)
      .setFont((font) => font.setWeight('bold'));

    const measureFPS = () => {
      const now = window.performance.now();
      this.frameCount += 1;
      if (this.framePrevious) {
        this.frameDelaySum += now - this.framePrevious;
      }
      this.framePrevious = now;
      requestAnimationFrame(() => measureFPS());
    };
    requestAnimationFrame(() => measureFPS());


    setInterval(() => {
      const now = window.performance.now();
      const fps = 1000 / (this.frameDelaySum / this.frameCount);
      indicatorFPS.setText(`${fpsPrefix}: ${fps.toFixed(1)}`);

      this.frameDelaySum = 0;
      this.frameCount = 0;
      this.pointsAdded = 0;
      this.displayPrevious = now;
    }, 1000);
  }
}

export class ThresholdLine {

  private line: ConstantLine;

  setValue(value: number) {
    this.line.setValue(value);
  }

  constructor(yAxis: Axis, threshold: number = 0) {
    this.line = yAxis.addConstantLine()
      .setValue(threshold)
      .setStrokeStyle(
        new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({
            color: ColorHEX('#66cccc')
          })
        })
      );
  }
}
