import { ContractionType } from "@app/types";

export const templateOverride = {
  glazer: {
    calibration: true,
    eventPreTime: 1,
    short: 'glazer',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_protokol_Glazera.png',
    report: 'glazer',
    threshold: 0,
    mvc: 1000,
    instructions: 'instructions.glazer',
    disabledSettings: true,
    incontinence: true,
    steps: [
      {
        type: ContractionType.FLICK,
        workTime: 1,
        restTime: 10,
        repetitions: 5,
        threshold: 0.7,
        duration: 110,
        endTime: 110,
        startRelaxDuration: 60
      },
      {
        type: ContractionType.NORMAL,
        workTime: 10,
        restTime: 10,
        repetitions: 5,
        threshold: 0.4,
        duration: 100,
        endTime: 210,
        startRelaxDuration: 0
      },
      {
        type: ContractionType.HOLD,
        workTime: 60,
        restTime: 60,
        repetitions: 1,
        threshold: 0.2,
        duration: 130,
        endTime: 340,
        startRelaxDuration: 0
      }
    ]
  },
  quick_flicks: {
    calibration: true,
    short: 'biofeedback_quick_flicks',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_biofeedback.png',
    eventPreTime: 1,
    report: 'biofeedback',
    threshold: 0,
    mvc: 1000,
    steps: [
      {
        startRelaxDuration: 5,
        workTime: 1,
        restTime: 2,
        repetitions: 5,
        threshold: 0.7,
        type: ContractionType.FLICK,
      },
      {
        startRelaxDuration: 5,
        workTime: 1,
        restTime: 2,
        repetitions: 5,
        threshold: 0.7,
        type: ContractionType.FLICK,
      },
      {
        startRelaxDuration: 5,
        workTime: 1,
        restTime: 2,
        repetitions: 5,
        threshold: 0.7,
        type: ContractionType.FLICK,
      }
    ]
  },
  grasp_and_release: {
    short: 'grasp_and_release',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_FES_chwyc_pusc.png',
    slug: 'grasp_and_release',
    report: 'stim',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        pulseDuration: [
            50,
            100,
            150,
            200,
            250,
            300,
            350,
            500
        ]
    },
    details: {
        channels: 3,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 50
        },
        pulseDuration: 200,
        workTime: 12000000,
        restTime: 9999670,
        rampUp: 1000000,
        rampDown: 1000000,
        frequency: 35,
        period: 28571,
        duration: 1200000000,
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 200,
            workTime: 9999850,
            frequency: 35,
            period: 28571,
            rampUp: 0,
            rampDown: 0
        }
    },
    sequence: [
        {
            time: 0,
            channel: 0,
            rampUp: 1000000,
            workTime: 10000000,
            rampDown: 1000000
        },
        {
            time: 2999955,
            channel: 1,
            rampUp: 1000000,
            workTime: 7000000,
            rampDown: 1000000
        },
        {
            time: 5999910,
            channel: 2,
            rampUp: 1000000,
            workTime: 4000000,
            rampDown: 1000000
        }
    ]
  },
  severe_atrophy: {
    short: 'severe_atrophy',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMS_odnerwienie_ostre.png',
    slug: 'severe_atrophy',
    report: 'stim',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        pulseDuration: [
            50000,
            100000,
            150000,
            200000,
            250000,
            300000
        ],
        frequency: [
            0.1,
            0.2,
            0.5
        ],
        channels: [
            1,
            2
        ]
    },
    details: {
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'TRAPEZOIDAL',
        currentRange: {
            min: 0,
            max: 80
        },
        pulseDuration: 200000,
        workTime: 10000000,
        restTime: 0,
        rampUp: 0,
        rampDown: 0,
        slope: 50000,
        frequency: 0.1,
        channelsAmount: 1,
        duration: 900000000,
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'TRAPEZOIDAL',
            pulseDuration: 200000,
            workTime: 10000000,
            frequency: 0.1,
            period: 1000000,
            rampUp: 0,
            rampDown: 0,
            slope: 50000
        }
    }
  },
  hand_to_mouth: {
    short: 'hand_to_mouth',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_FES_reka_do_twarzy.png',
    slug: 'hand_to_mouth',
    report: 'stim',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        pulseDuration: [
            50,
            100,
            150,
            200,
            250,
            300,
            350,
            500
        ]
    },
    details: {
        channels: 4,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 50
        },
        pulseDuration: 200,       // Pulse duration time [us]
        workTime: 9000000,        // Total work time (rampUp + plateau + rampDown) [us]
        restTime: 8999730,        // Total pause time [us]
        rampUp: 1000000,          // Ramp up total time [us]
        rampDown: 1000000,        // Ramp down time [us]
        frequency: 35,            // Signal frequency
        period: 28571,            // Signal period (from pulse start to next) [us] Attention: (workTime + restTime) %  28571 must be 0.
        duration: 1200000000,     // Total program time [us] Warn: duration/(workTime + restTime) should be integer
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 200,
            workTime: 6999895,
            frequency: 35,
            period: 28571,
            rampUp: 0,
            rampDown: 0
        }
    },
    sequence: [
        {
            time: 0,
            channel: 0,
            rampUp: 1000000,
            workTime: 7000000,
            rampDown: 1000000
        },
        {
            time: 999985,         // Start Time Offset [us]
            channel: 1,           // Channel
            rampUp: 1000000,      // Ramp up time [us]
            workTime: 5000000,    // Plateau time [us]
            rampDown: 1000000     // Ramp down time [us]
        },
        {
            time: 999985,
            channel: 2,
            rampUp: 1000000,
            workTime: 5000000,
            rampDown: 1000000
        },
        {
            time: 999985,
            channel: 3,
            rampUp: 1000000,
            workTime: 5000000,
            rampDown: 1000000
        }
    ]
  },
  arm_extension_support: {
    short: 'arm_extension_support',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_FES_wyprost_podparcie.png',
    slug: 'arm_extension_support',
    report: 'stim',
    adjustable: {
      duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        pulseDuration: [
            50,
            100,
            150,
            200,
            250,
            300,
            350,
            500
        ]
    },
    details: {
        channels: 3,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 50
        },
        pulseDuration: 200,
        workTime: 9000000,
        restTime: 8999730,
        rampUp: 1000000,
        rampDown: 1000000,
        frequency: 35,
        period: 28571,
        duration: 1200000000,
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 200,
            workTime: 6999895,
            frequency: 35,
            period: 28571,
            rampUp: 0,
            rampDown: 0
        }
    },
    sequence: [
        {
            time: 0,
            channel: 0,
            rampUp: 1000000,
            workTime: 7000000,
            rampDown: 1000000
        },
        {
            time: 999985,
            channel: 1,
            rampUp: 1000000,
            workTime: 5000000,
            rampDown: 1000000
        },
        {
            time: 1999970,
            channel: 2,
            rampUp: 1000000,
            workTime: 4000000,
            rampDown: 1000000
        }
    ]
  },
  pain_tens_burst : {
    short: 'pain_tens_burst',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_TENS_burst.png',
    report: 'stim',
    slug: 'pain_tens_burst',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        channels: [
            1,
            2,
            3,
            4
        ]
    },
    details: {
        pulseDuration: 200,
        workTime: 400000,
        restTime: 100000,
        rampUp: 0,
        rampDown: 0,
        frequency: 80,
        duration: 900000000,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 100
        },
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 200,
            workTime: 10000000,
            frequency: 80,
            period: 12500,
            rampUp: 0,
            rampDown: 0
        }
    }
  },
  pain_tens_conventional : {
    short: 'pain_tens_conventional',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_TENS_konwencjonalny.png',
    report: 'stim',
    slug: 'pain_tens_conventional',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        channels: [
            1,
            2,
            3,
            4
        ]
    },
    details: {
        duration: 900000000,
        pulseDuration: 150,
        frequency: 80,
        rampUp: 0,
        rampDown: 0,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 80
        },
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 150,
            workTime: 10000000,
            frequency: 80,
            period: 12500,
            rampUp: 0,
            rampDown: 0
        }
    }
  },
  pain_tens_frequency: {
    iconName: "exercise-icons/EGZOTech_StellaBIO_TENS_modulowany.png",
    report: "stim",
    short: "pain_tens_frequency",
    slug: "pain_tens_frequency",
    adjustable: {
      channels: [1, 2, 3, 4],
      duration: [
        300000000,
        600000000,
        900000000,
        1200000000,
        1500000000,
        1800000000,
        2100000000,
        2400000000,
        2700000000,
        3000000000,
        3600000000]
    },
    details: {
      current: "BIPHASIC_SYMMETRIC",
      currentRange: {min: 0, max: 100},
      currentType: "TENS",
      customProgram: 200,
      duration: 900000000,
      pulseDuration: 200,
      rampDown: 0,
      rampUp: 0,
      stimCalibration: {
        current: "BIPHASIC_SYMMETRIC",
        currentType: "RECTANGULAR",
        frequency: 100,
        period: 10000,
        pulseDuration: 200,
        rampDown: 0,
        rampUp: 0,
        workTime: 10000000
      }
    }
  },
  pain_tens_acupuncture : {
    short: 'pain_tens_acupuncture',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_TENS_akupunktura.png',
    report: 'stim',
    slug: 'pain_tens_acupuncture',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        channels: [
            1,
            2,
            3,
            4
        ]
    },
    details: {
        duration: 900000000,
        pulseDuration: 200,
        frequency: 4,
        rampUp: 0,
        rampDown: 0,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 100
        },
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 200,
            workTime: 10000000,
            frequency: 4,
            period: 250000,
            rampUp: 0,
            rampDown: 0
        }
    }
  },
  pain_user_program : {
    short: 'pain_user_program',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_TENS_burst.png',
    report: 'stim',
    slug: 'pain_user_program',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        channels: [
            1,
            2,
            3,
            4
        ],
        frequency: [
            5,
            10,
            20,
            25,
            40,
            50,
            80,
            100
        ],
        pulseDuration: [
            50,
            100,
            150,
            200,
            250,
            300,
            350,
            400,
            450,
            500
        ]
    },
    details: {
        duration: 900000000,
        pulseDuration: 150,
        frequency: 80,
        rampUp: 0,
        rampDown: 0,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 100
        },
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 150,
            workTime: 10000000,
            frequency: 80,
            period: 12500,
            rampUp: 0,
            rampDown: 0
        }
    }
  },
  atrophy: {
    short: 'atrophy',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMS_atrofia.png',
    slug: 'atrophy',
    report: 'stim',
    adjustable: {
        warmup: [
            true,
            false
        ],
        cooldown: [
            true,
            false
        ]
    },
    details: {
        warmup: true,
        cooldown: true,
        duration: 2400000000,
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
            min: 0,
            max: 100
        },
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'RECTANGULAR',
            pulseDuration: 300,
            workTime: 10000200,
            frequency: 60,
            period: 16667,
            rampUp: 0,
            rampDown: 0
        }
    },
    phases: [
        {
            frequency: 10,
            pulseDuration: 300,
            duration: 300000000,
            rampUp: 0,
            rampDown: 0
        },
        {
            frequency: 40,
            pulseDuration: 300,
            workTime: 8000000,
            restTime: 16000000,
            duration: 900000000,
            rampUp: 2000000,
            rampDown: 1000000
        },
        {
            frequency: 60,
            pulseDuration: 300,
            workTime: 8000000,
            restTime: 16000540,
            duration: 900000000,
            rampUp: 2000000,
            rampDown: 1000000
        },
        {
            frequency: 3,
            pulseDuration: 300,
            duration: 299999700,
            rampUp: 0,
            rampDown: 0
        }
    ]
  },
  mild_atrophy: {
    short: 'mild_atrophy',
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMS_odnerwienie_lagodne.png',
    slug: 'mild_atrophy',
    report: 'stim',
    adjustable: {
        duration: [
            300000000,
            600000000,
            900000000,
            1200000000,
            1500000000,
            1800000000,
            2100000000,
            2400000000,
            2700000000,
            3000000000,
            3300000000,
            3600000000
        ],
        pulseDuration: [
            1000,
            2000,
            5000,
            10000,
            20000
        ],
        frequency: [
            1,
            2,
            5,
            10,
            20
        ],
        channels: [
            1,
            2
        ]
    },
    details: {
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'TRIANGULAR',
        currentRange: {
            min: 0,
            max: 80
        },
        pulseDuration: 10000,
        workTime: 3000000,
        restTime: 6000000,
        rampUp: 0,
        rampDown: 0,
        frequency: 20,
        channelsAmount: 1,
        duration: 1200000000,
        stimCalibration: {
            current: 'BIPHASIC_SYMMETRIC',
            currentType: 'TRIANGULAR',
            pulseDuration: 10000,
            workTime: 3000000,
            frequency: 20,
            period: 50000,
            rampUp: 0,
            rampDown: 0
        }
    }
  },
  emg_trigger: {
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_EMS_nietrz_moczu_1kanal.png',
    short: 'iemg_trigger',
    slug: 'emg_trigger',
    adjustable: {
        repetitions: [
            5,
            10,
            15,
            20,
            25,
            30
        ]
    },
    details: {
        calibration: true,
        emgTriggered: true,
      duration: 900000000,
      pulseDuration: 200,
      frequency: 35,
      period: 28571,
      rampUp: 500000,
      rampDown: 500000,
      workTime: 3000000,
      restTime: 5999850,
      current: 'BIPHASIC_SYMMETRIC',
      currentType: 'RECTANGULAR',
      currentRange: {
          min: 0,
          max: 100
      },
      repetitions: 10,
      stimCalibration: {
          current: 'BIPHASIC_SYMMETRIC',
          currentType: 'RECTANGULAR',
          pulseDuration: 200,
          workTime: 5999910,
          frequency: 35,
          period: 28571,
          rampUp: 0,
          rampDown: 0
      }
    }
  },
  ems_sport_massage: {
    iconName: 'exercise-icons/EGZOTech_StellaBIO_TENS_masaz.png',
    short: 'ems_sport_massage',
    slug: 'ems_sport_massage',
    adjustable: {
      repetitions: [
        3,
        6,
        9,
        12,
        15,
        18,
        21,
        24,
        27,
        30,
        33,
        36
      ],
      channels: [
        1,
        2,
        3,
        4
      ]
    },
    details: {
      duration: 1800000000,
      pulseDuration: 250,
      rampUp: 0,
      rampDown: 0,
      current: 'BIPHASIC_SYMMETRIC',
      currentType: 'RECTANGULAR',
      currentRange: {
        min: 0,
        max: 100
      },
      repetitions: 18,
      stimCalibration: {
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        pulseDuration: 250,
        workTime: 6000000,
        frequency: 80,
        period: 12500,
        rampUp: 0,
        rampDown: 0
      }
    },
    phases: [
      {
        frequency: 80,
        duration: 20000000
      },
      {
        frequency: 75,
        duration: 19999500
      },
      {
        frequency: 10,
        duration: 20000000
      },
      {
        frequency: 70,
        duration: 20000400
      },
      {
        frequency: 65,
        duration: 20000500
      }
    ]
  },
  incontinence_anal_trigger: {
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_EMS_nietrz_stolca.png',
    short: 'incontinence_anal_trigger',
    bodyPart: 'pelvic',
    slug: 'incontinence_anal_trigger',
    incontinence: true,
    adjustable: {
      repetitions: [
        5,
        10,
        15,
        20,
        25,
        30
      ]
    },
    details: {
      channels: 1,
      calibration: true,
      emgTriggered: true,
      duration: 1200000000,
      pulseDuration: 200,
      frequency: 35,
      rampUp: 1000000,
      rampDown: 1000000,
      workTime: 5000000,
      restTime: 4999820,
      current: 'BIPHASIC_SYMMETRIC',
      currentType: 'RECTANGULAR',
      currentRange: {
        min: 0,
        max: 100
      },
      repetitions: 10,
      stimCalibration: {
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        pulseDuration: 200,
        workTime: 4999925,
        frequency: 35,
        period: 28571,
        rampUp: 0,
        rampDown: 0
      }
    }
  },
  incontinence_emg_trigger: {
    iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_EMS_nietrz_moczu_1kanal.png',
    short: 'incontinence_emg_trigger',
    bodyPart: 'pelvic',
    slug: 'incontinence_emg_trigger',
    incontinence: true,
    adjustable: {
      repetitions: [
        5,
        10,
        15,
        20,
        25,
        30
      ]
    },
    details: {
      channels: 1,
      calibration: true,
      emgTriggered: true,
      duration: 900000000,
      pulseDuration: 200,
      frequency: 35,
      rampUp: 1000000,
      rampDown: 1000000,
      workTime: 4000000,
      restTime: 5999820,
      current: 'BIPHASIC_SYMMETRIC',
      currentType: 'RECTANGULAR',
      currentRange: {
        min: 0,
        max: 100
      },
      repetitions: 10,
      stimCalibration: {
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        pulseDuration: 200,
        workTime: 3999940,
        frequency: 35,
        period: 28571,
        rampUp: 0,
        rampDown: 0
      }
    }
  },
  custom: {
    calibration: true,
    eventPreTime: 1,
    iconName: "exercise-icons/EGZOTech_StellaBIO_EMG_biofeedback_wtrzymalosc.png",
    mvc: 1000,
    report: "biofeedback",
    short: "biofeedback_custom",
    threshold: 0,
    steps: [
      {
        startRelaxDuration: 10,
        workTime: 5,
        restTime: 10,
        repetitions: 5,
        threshold: 0.5,
        duration: 85,
      }
    ]
  },
  view: {
    allowed: true,
    createdAt: "2021-07-09T15:21:15.778Z",
    extras: {
      admin: true,
      conceptCategories: ["neuro", "ortho", "pelvic", "sport"],
      functionalCategory: "emg",
    },
    iconName: "exercise-icons/EGZOTech_StellaBIO_EMG_biofeedback.png",
    id: 11,
    licenses: ["emg_biofeedback"],
    name: "view",
    private: false,
    steps: { mvc: 1000, report: "emg", short: "emg_quick_view", threshold: 0 },
    type: "diagnostic",
    updatedAt: "2021-07-09T15:21:15.778Z",
    version: 1,
  },
  ems_sport_muscle_strenghtening: {
    short: "ems_sport_muscle_strenghtening",
    iconName: "exercise-icons/EGZOTech_StellaBIO_SPORT_sila.png",
    report: "stim",
    slug: "ems_sport_muscle_strenghtening",
    adjustable: {
      duration: [
        300000000,
        600000000,
        900000000,
        1200000000,
        1500000000,
        1800000000,
        2100000000,
        2400000000,
        2700000000,
        3000000000,
        3300000000,
        3600000000
      ],
      channels: [
        1,
        2,
        3,
        4
      ]
    },
    details: {
      duration: 600000000,
      pulseDuration: 300,
      frequency: 50,
      rampUp: 2000000,
      rampDown: 3000000,
      workTime: 5000000,
      restTime: 10000000,
      current: "BIPHASIC_SYMMETRIC",
      currentType: "RECTANGULAR",
      currentRange: {
        min: 0,
        max: 100
      },
      stimCalibration: {
        current: "BIPHASIC_SYMMETRIC",
        currentType: "RECTANGULAR",
        pulseDuration: 200,
        workTime: 5000000,
        frequency: 50,
        period: 20000,
        rampUp: 0,
        rampDown: 0
      }
    }
  },
  agonist_antagonist:{
      short: "agonist_antagonist",
      iconName: "exercise-icons/EGZOTech_StellaBIO_EMS_biceps_triceps.png",
      slug: "agonist_antagonist",
      report: "stim",
    extras: {
      admin: true,
      conceptCategories: [
        "sport"
      ],
      functionalCategory: "ems"
    },
    licenses: [
      "sport"
    ],
    createdAt: "2021-07-09T15:21:16.236Z",
    updatedAt: "2021-07-09T15:21:16.236Z",
      version: 1,
      name: "agonist_antagonist",
      type: "electrostim",
      private: false,
      steps: {
        adjustable: {
          warmup: [
            true,
            false
          ],
          cooldown: [
            true,
            false
          ]
        }},
      details: {
        duration: 2400000000,
        channels: 4,
        current: "BIPHASIC_SYMMETRIC",
        currentType: "RECTANGULAR",
        currentRange: {
          "min": 0,
          "max": 100
        },
        warmup: true,
        cooldown: true,
        stimCalibration: {
          current: "BIPHASIC_SYMMETRIC",
          currentType: "RECTANGULAR",
          pulseDuration: 300,
          workTime: 8000000,
          frequency: 40,
          period: 25000,
          rampUp: 0,
          rampDown: 0
        }
      },
      phases: [
        {
          channels: [
            0,
            1,
            2,
            3
          ],
          frequency: 10,
          pulseDuration: 300,
          duration: 300000000,
          rampUp: 2000000,
          rampDown: 1000000
        },
        {
          duration: 900000000,
          sequenceTime: 22000000,
          sequence: [
            {
              channel: 0,
              time: 0,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 40,
              pulseDuration: 300
            },
            {
              channel: 2,
              time: 0,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 40,
              pulseDuration: 300
            },
            {
              channel: 1,
              time: 11000000,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 40,
              pulseDuration: 300
            },
            {
              channel: 3,
              time: 11000000,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 40,
              pulseDuration: 300
            }
          ]
        },
        {
          duration: 900000000,
          sequenceTime: 22000000,
          sequence: [
            {
              channel: 0,
              time: 0,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 60,
              pulseDuration: 300
            },
            {
              channel: 2,
              time: 0,
              rampUp: 2000000,
              rampDow: 1000000,
              workTime: 8000000,
              frequency: 60,
              pulseDuration: 300
            },
            {
              channel: 1,
              time: 11000000,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 60,
              pulseDuration: 300
            },
            {
              channel: 3,
              time: 11000000,
              rampUp: 2000000,
              rampDown: 1000000,
              workTime: 8000000,
              frequency: 60,
              pulseDuration: 300
            }
          ]
        },
        {
          channels: [
            0,
            1,
            2,
            3
          ],
          frequency: 3,
          pulseDuration: 300,
          duration: 300000000,
          rampUp: 2000000,
          rampDown: 1000000
        }
      ],
    allowed: true
  }
}

export const templateOverrideAdditional = [
  {
    allowed: true,
    createdAt: "2021-10-21T14:14:15.743Z",
    extras: {
      admin: true,
      conceptCategories: [
        "ortho"
      ],
      functionalCategory: "emg"
    },
    licenses: [
      "emg_biofeedback"
    ],
    version: 1,
    id: 47,
    name: "low_back_pain",
    type: "diagnostic",
    private: false,
    steps: {
      disabledSettings: true,
      mvc: 1000,
      short: "low_back_pain",
      report: "low_back_pain",
      iconName: "exercise-icons/EGZOTech_StellaBIO_EMG_standing_hamstring_stretch.png",
      threshold: 0,
      calibration: true,
      eventPreTime: 1,
      steps: [
        {
          workTime: 5,
          restTime: 10,
          repetitions: 1,
          startRelaxDuration: 10,
          threshold: 0.5,
          removable: false,
          duration: 15,
          endTime: 25,
        },
        {
          workTime: 5,
          restTime: 10,
          repetitions: 1,
          startRelaxDuration: 10,
          threshold: 0.5,
          removable: false,
          duration: 15,
          endTime: 40,
        },
        {
          workTime: 5,
          restTime: 10,
          repetitions: 1,
          startRelaxDuration: 10,
          threshold: 0.5,
          removable: false,
          duration: 15,
          endTime: 55,
        },
        {
          workTime: 5,
          restTime: 10,
          repetitions: 1,
          startRelaxDuration: 10,
          threshold: 0.5,
          removable: false,
          duration: 15,
          endTime: 70,
        },
        {
          workTime: 5,
          restTime: 10,
          repetitions: 1,
          startRelaxDuration: 10,
          threshold: 0.5,
          removable: false,
          duration: 15,
          endTime: 85,
        }
      ]
    },
    updatedAt: "2021-10-21T14:14:15.743Z",
  },
  {
    allowed: true,
    createdAt: "2021-10-21T14:14:15.743Z",
    extras: {
      admin: true,
      conceptCategories: [
        "ortho"
      ],
      functionalCategory: "emg"
    },
    licenses: [
      "emg_biofeedback"
    ],
    version: 1,
    id: 48,
    name: "sorens",
    type: "diagnostic",
    private: false,
    steps: {
      disabledSettings: true,
      mvc: 1000,
      short: "sorens",
      report: "sorens",
      iconName: "exercise-icons/EGZOTech_StellaBIO_EMG_sorens_test.png",
      threshold: 0,
      calibration: true,
      eventPreTime: 1,
      steps: [{
        workTime: 120,
        restTime: 60,
        repetitions: 1,
        startRelaxDuration: 60,
        threshold: 0.2,
        removable: false,
        duration: 240,
        endTime: 240,
      }]
    },
    updatedAt: "2021-10-21T14:14:15.743Z",
    varsion: 1
  },
  {
    allowed: true,
    private: false,
    createdAt: "2022-04-12T14:14:15.743Z",
    extras: {
      admin: true,
      conceptCategories: [
        "neuro"
      ],
      functionalCategory: "ems"
    },
    licenses: [
      "neuro"
    ],
    id: 49,
    name: "ems_muscle_spasm_relaxation",
    type: "electrostim",
    steps: {
      slug: 'ems_muscle_spasm_relaxation',
      iconName: 'exercise-icons/EGZOTech_StellaBIO_EMS_muscle_spasm.png',
      short: "ems_muscle_spasm_relaxation",
      report: 'stim',
      details: {
        current: 'BIPHASIC_SYMMETRIC',
        currentType: 'RECTANGULAR',
        currentRange: {
          min: 0,
          max: 100
        },

        channelsAmount: 1,
        pulseDuration: 250,
        duration: 1200000000,
        frequency: 80,
        rampUp: 2000000,
        rampDown: 2000000,
        workTime: 8000000,
        restTime: 4000000,
        stimCalibration: {
          current: 'BIPHASIC_SYMMETRIC',
          currentType: 'RECTANGULAR',
          pulseDuration: 250,
          duration: 16000000,
          frequency: 80,
          rampUp: 2000000,
          rampDown: 2000000,
          workTime: 8000000,
          restTime: 4000000,
        }
      }
    },
    updatedAt: "2022-04-12T15:21:16.593Z",
    version: 1,
  },
  {
    allowed: true,
    private: false,
    id: 50,
    name: 'relaxation_analysis',
    type: 'diagnostic',
    steps: {
        iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_biofeedback.png',
        mvc: 1000,
        report: 'emg',
        short: 'emg_relaxation_analysis',
        threshold: 0,
    },
    extras: {
      admin: true,
      conceptCategories: ['neuro', 'ortho', 'pelvic', 'sport'],
      functionalCategory: 'emg',
    },
    licenses: ['emg_biofeedback'],
    createdAt: '2022-03-20T15:21:15.778Z',
    updatedAt: '2022-03-20T15:21:15.778Z',
    version: 1,
  },
  {
    allowed: true,
    private: false,
    id: 51,
    name: 'view_with_mvc',
    type: 'diagnostic',
    steps: {
        iconName: 'exercise-icons/EGZOTech_StellaBIO_EMG_biofeedback.png',
        mvc: 1000,
        report: 'emg',
        short: 'emg_view_with_mvc',
        threshold: 0,
    },
    extras: {
      admin: true,
      conceptCategories: ['neuro', 'ortho', 'pelvic', 'sport'],
      functionalCategory: 'emg',
    },
    licenses: ['emg_biofeedback'],
    createdAt: '2022-04-18T15:21:15.778Z',
    updatedAt: '2022-04-18T15:21:15.778Z',
    version: 1,
  },
  {
    extras: {
      admin: true,
      conceptCategories: [
        "pelvic",
      ],
      functionalCategory: "pelvic"
    },
    id: 52,
    name: "ems_muscle_relaxation_pelvic_bursts",
    type: "electrostim",
    private: false,
    licenses: ["pelvic"],
    steps: {
      bodyPart: "pelvic",
      short: "ems_muscle_relaxation_pelvic_bursts",
      iconName: 'exercise-icons/EGZOTech_StellaBIO_EMS_rozluznienie_pelvic_przewlekla_hipertonia.png',
      slug: "ems_muscle_relaxation_pelvic_bursts",
      incontinence:true,
      adjustable: {
        duration: [
          300000000,
          600000000,
          900000000,
          1200000000,
          1500000000,
          1800000000
        ],
        frequency: [
          2,
          4,
          8
        ],
        pulseDuration: [
          200,
          220,
          250
        ]
      },
      details: {
        duration: 1200000000,
        pulseDuration: 220,
        frequency: 2,
        current: "BIPHASIC_SYMMETRIC",
        currentType: "RECTANGULAR",
        rampUp: 1000000,
        rampDown: 1000000,
        workTime: 6000000,
        restTime: 10000000,
        currentRange: {
          min: 0,
          max: 70
        },
        stimCalibration: {
          duration: 1200000000,
          pulseDuration: 220,
          frequency: 2,
          current: "BIPHASIC_SYMMETRIC",
          currentType: "RECTANGULAR",
          rampUp: 1000000,
          rampDown: 1000000,
          workTime: 6000000,
          restTime: 10000000
        }
      }
    },
    allowed: true,
  },
  {
    extras: {
      admin: true,
      conceptCategories: [
        "pelvic",
      ],
      functionalCategory: "pelvic"
    },
    id: 53,
    name: "ems_muscle_relaxation_pelvic_continuous",
    type: "electrostim",
    private: false,
    licenses: ["pelvic"],
    steps: {
      bodyPart: "pelvic",
      short: "ems_muscle_relaxation_pelvic_continuous",
      iconName: 'exercise-icons/EGZOTech_StellaBIO_EMS_rozluznienie_pelvic.png',
      slug: "ems_muscle_relaxation_pelvic_continuous",
      incontinence: true,
      adjustable: {
        duration: [
          300000000,
          600000000,
          900000000,
          1200000000,
          1500000000,
          1800000000
        ],
        frequency: [
          1,
          2,
          5,
          8,
          10
        ],
        pulseDuration: [
          50,
          70,
          100,
          150,
          200,
          250,
          300,
          400
        ]
      },
      details: {
        duration: 1200000000,
        pulseDuration: 150,
        frequency: 2,
        current: "BIPHASIC_SYMMETRIC",
        currentType: "RECTANGULAR",
        rampUp: 0,
        rampDown: 0,
        currentRange: {
          min: 0,
          max: 70
        },
        stimCalibration: {
          duration: 1200000000,
          pulseDuration: 150,
          frequency: 2,
          current: "BIPHASIC_SYMMETRIC",
          currentType: "RECTANGULAR",
          rampUp: 0,
          rampDown: 0,
          workTime: 5000000
        }
      }
    },
    allowed: true,
  },
  {
    extras: {
      admin: true,
      conceptCategories: [
        "pelvic",
      ],
      functionalCategory: "pelvic"
    },
    id: 54,
    name: "ems_user_program_pelvic",
    type: "electrostim",
    private: false,
    licenses: ["pelvic"],
    steps: {
      bodyPart:'pelvic',
      short: "ems_user_program_pelvic",
      iconName: 'exercise-icons/EGZOTech_StellaBIO_TENS_burst.png',
      slug: "ems_user_program_pelvic",
      incontinence:true,
      adjustable: {
        duration: [
          300000000,
          600000000,
          900000000,
          1200000000,
          1500000000,
          1800000000,
          2100000000,
          2400000000,
          2700000000,
          3000000000,
          3300000000,
          3600000000
        ],
        channels: [
          1,
          2
        ],
        frequency: [
          1,
          2,
          5,
          8,
          10,
          20,
          25,
          35,
          40,
          50
        ],
        pulseDuration: [
          50,
          100,
          150,
          200,
          250,
          300,
          350,
          400,
          450,
          500,
          700,
          1000
        ],
        rampDown: [
          0,
          1000000,
          2000000,
          3000000,
          4000000
        ],
        rampUp: [
          0,
          1000000,
          2000000,
          3000000,
          4000000
        ],
        workTime: [
          1000000,
          2000000,
          3000000,
          4000000,
          5000000,
          6000000,
          7000000,
          8000000,
          9000000,
          10000000,
          11000000,
          12000000,
          13000000,
          14000000,
          15000000,
          16000000,
          17000000,
          18000000,
          19000000,
          20000000
        ],
        restTime: [
          1000000,
          2000000,
          3000000,
          4000000,
          5000000,
          6000000,
          7000000,
          8000000,
          9000000,
          10000000,
          11000000,
          12000000,
          13000000,
          14000000,
          15000000,
          16000000,
          17000000,
          18000000,
          19000000,
          20000000
        ]
      },
      details: {
        duration: 1200000000,
        pulseDuration: 200,
        frequency: 35,
        current: "BIPHASIC_SYMMETRIC",
        currentType: "RECTANGULAR",
        rampUp: 1000000,
        rampDown: 1000000,
        workTime: 3000000,
        restTime: 2000000,
        plateau: 1000000,

        currentRange: {
          min: 0,
          max: 100
        },
        repetitions: 10,
        stimCalibration: {
          duration: 1200000000,
          pulseDuration: 200,
          frequency: 35,
          current: "BIPHASIC_SYMMETRIC",
          currentType: "RECTANGULAR",
          rampUp: 1000000,
          rampDown: 1000000,
          workTime: 3000000,
          restTime: 2000000,
          plateau: 1000000,
        }
      }
    },
    allowed: true,
  }
];
