import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardMode, MINIMUM_STELLA_OS_VERSION } from '@app/enums';
import { ListStellaService } from '@app/stella/services/list-stella.service';
import { TranslateService } from '@ngx-translate/core';
import { StellaKioskService } from '@app/shared/services/stella-kiosk.service';
import { MatDialog } from '@angular/material';
import { UpgradeDialogComponent } from '@app/components/upgrade-dialog/upgrade-dialog.component';

@Component({
  selector: 'sba-stella',
  templateUrl: './list-stellas.component.html',
  styleUrls: ['./list-stellas.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0
        })
      ),
      transition('void <=> *', animate(1000))
    ]),
    trigger('slideTop', [
      state(
        'void',
        style({
          opacity: 0,
          transform: 'translateY(-200%)'
        })
      ),
      transition('void <=> *', animate(300))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListStellasComponent implements OnInit {
  constructor(
    public readonly listStella: ListStellaService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly dashboard: DashboardService,
    private readonly translate: TranslateService,
    private readonly stellaKiosk: StellaKioskService,
    private readonly dialog: MatDialog,
    title: Title
  ) {
    title.setTitle('Select Stella | Stella BIO App');
    this.changeStella = this.hasExtraState(router) ? Boolean(router.getCurrentNavigation().extras.state.change) : false;
  }

  private changeStella = false;

  ngOnInit() {
    if (this.listStella.implicit()) {
      this.router.navigate(['dashboard'], { replaceUrl: true });
      return;
    }
  }

  handleClick(stella) {
    if (stella.device?.os?.name === "BioMea" && stella.device?.os?.version < MINIMUM_STELLA_OS_VERSION) {
      this.dialog.open(UpgradeDialogComponent, { maxWidth: '450px', data: { message: "stella.needsUpgrade", allowUserClose: true } });
      return;
    }

    this.listStella.selectStella(stella);
    if (this.changeStella) {
      return this.goBack();
    }
    if (this.dashboard.mode === DashboardMode.PATIENT) {
      this.router.navigate([this.dashboard.trainingTemplate.meetings[0].template.path], { replaceUrl: true });
    } else {
      this.router.navigate([Pages.IDLE], { replaceUrl: true });
    }
  }

  trackStella = (_, stella) => stella.token;
  goBack = () => this.location.back();
  handleError = _ => { };
  private hasExtraState = (router) => router.getCurrentNavigation() && router.getCurrentNavigation().extras.state;

  skipStella() {
    // this.listStella.clearStella();
    switch (this.dashboard.mode) {
      case DashboardMode.PATIENT:
        this.router.navigate([Pages.PATIENT_DASHBOARD]);
        break;
      default:
        this.router.navigate([Pages.PATIENT_MEDICAL_CARD]);
        break;
    }
  }

  handleConnectClick() {
    if (this.stellaKiosk.isKioskMode()) {
      this.stellaKiosk.showRegisterDevicesPopup(this.translate.currentLang);
    }
    else {
      this.router.navigate([Pages.STELLA_CONNECT_NEW]);
    }
  }
}
